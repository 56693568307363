import { useEffect } from 'react';
import {
  ColorScheme,
  Organization,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@carousel/nexus';
import { RootState } from '../../../store/store';
import { useAppSelector } from '../../../store/hooks';
import { useDispatch } from 'react-redux';
import {
  setDivisionsForOrganization,
  setSelectedDivision,
} from '../../../store/slices/division.slice';
import {
  setSelectedSubDivision,
  setSubDivisionsForDivision,
} from '../../../store/slices/sub-division.slice';

const DivisionSubDivisionSelection = ({
  colors,
  isMobile,
  organization,
}: {
  colors: ColorScheme;
  isMobile: boolean;
  organization: Organization;
}) => {
  const dispatch = useDispatch();

  const divisions = organization.divisions
    .map((division) => {
      return {
        name: division.name,
        id: division.id,
        subDivisions: division.sub || [],
      };
    })
    .sort((a, b) => a.name.localeCompare(b.name));

  // Create the combined array with "All Divisions" at the top
  const displayDivisions = [{ name: 'All Divisions', id: 'all' }, ...divisions];

  const selectedDivision = useAppSelector(
    (state: RootState) => state.division.selected,
  );

  const selectedSubDivision = useAppSelector(
    (state: RootState) => state.subDivision.selected,
  );

  const isSideNavOpen = useAppSelector(
    (state: RootState) => state.layout.isSideNavOpen,
  );

  // Get subdivisions for the selected division
  const getSubDivisionsForDivision = () => {
    if (!selectedDivision || selectedDivision.id === 'all') return [];
    const division = divisions.find((d) => d.id === selectedDivision.id);
    return division?.subDivisions || [];
  };

  // Create display subdivisions with "All" option
  const displaySubDivisions = [
    { name: 'All Sub-Divisions', id: 'all' },
    ...getSubDivisionsForDivision(),
  ];

  const handleDivisionChange = (value: string) => {
    if (value === 'all') {
      dispatch(setSelectedDivision(null));
      dispatch(setSelectedSubDivision(null)); // Clear subdivision when division is cleared
      dispatch(setSubDivisionsForDivision([])); // Clear subdivision list
    } else {
      const div = organization.divisions.find((d) => d.id === value);
      if (div) {
        dispatch(setSelectedDivision(div));
        dispatch(setSelectedSubDivision(null)); // Reset subdivision when division changes
      }
    }
  };

  const handleSubDivisionChange = (value: string) => {
    if (value === 'all') {
      dispatch(setSelectedSubDivision(null));
    } else {
      const subDiv = getSubDivisionsForDivision().find((sd) => sd.id === value);
      if (subDiv) {
        dispatch(setSelectedSubDivision(subDiv));
      }
    }
  };

  // Effect to update divisions when organization changes
  useEffect(() => {
    dispatch(setDivisionsForOrganization(organization.divisions));
  }, [organization, dispatch]);

  // Effect to update subdivisions when selected division changes
  useEffect(() => {
    if (selectedDivision && selectedDivision.id !== 'all') {
      const division = divisions.find((d) => d.id === selectedDivision.id);
      dispatch(setSubDivisionsForDivision(division?.subDivisions || []));
    } else {
      dispatch(setSubDivisionsForDivision([]));
    }
  }, [selectedDivision, divisions, dispatch]);

  return (
    <div
      className={`fixed left-0 right-0 z-50 ${
        isMobile ? 'top-[178px]' : 'top-[212px]'
      }`}
    >
      <div
        className={`relative transition-all duration-300 ease-in-out
          ${!isMobile && isSideNavOpen ? 'ml-[150px]' : 'ml-0'}`}
      >
        <div
          className={`w-full ${colors.bg.primary} shadow-lg border-t border-b border-gray-200/10`}
        >
          <div className="px-12">
            <div className="h-12 flex items-center justify-center">
              <div>
                <Select
                  colors={colors}
                  value={selectedDivision?.id || 'all'}
                  onValueChange={handleDivisionChange}
                >
                  <SelectTrigger
                    className={`w-[240px] border border-gray-200/20 bg-transparent
                    focus:ring-0 focus:ring-offset-0 ${colors.text}`}
                  >
                    <SelectValue placeholder="Select Division" />
                  </SelectTrigger>
                  <SelectContent
                    className={`${colors.bg.secondary} border-gray-200/20`}
                  >
                    {displayDivisions.map((division) => (
                      <SelectItem
                        key={division.id}
                        value={division.id}
                        className={`${colors.text} hover:${colors.text.accent3} cursor-pointer`}
                      >
                        {division.name}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>

              {selectedDivision && selectedDivision.id !== 'all' && (
                <div className="ml-4">
                  <Select
                    colors={colors}
                    value={selectedSubDivision?.id || 'all'}
                    onValueChange={handleSubDivisionChange}
                  >
                    <SelectTrigger
                      className={`w-[240px] border border-gray-200/20 bg-transparent
                      focus:ring-0 focus:ring-offset-0 ${colors.text}`}
                    >
                      <SelectValue placeholder="Select Sub-Division" />
                    </SelectTrigger>
                    <SelectContent
                      className={`${colors.bg.secondary} border-gray-200/20`}
                    >
                      {displaySubDivisions.map((subDivision) => (
                        <SelectItem
                          key={subDivision.id}
                          value={subDivision.id}
                          className={`${colors.text} hover:${colors.text.accent3} cursor-pointer`}
                        >
                          {subDivision.name}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { DivisionSubDivisionSelection };
