import { EventStatus, ScheduledEvent } from '@carousel/nexus';

const sampleEvents: ScheduledEvent[] = [
  {
    id: '4',
    status: EventStatus.LIVE,
    startTime: '2025-01-17T18:00:00Z',
    game: {
      id: 'csgo',
      name: 'Counter-Strike: Global Offensive',
      abbreviation: 'CS:GO',
    },
    division: {
      id: 'masters',
      name: 'Masters Division',
    },
    teams: {
      team1: {
        id: 'navi',
        name: 'Natus Vincere',
        abbreviation: 'NAVI',
        record: '15-3',
        score: '3',
        isWinner: true,
      },
      team2: {
        id: 'faze',
        name: 'FaZe Clan',
        abbreviation: 'FAZE',
        record: '13-5',
        score: '2',
      },
    },
    venue: {
      name: 'ESL Arena Cologne',
      city: 'Cologne',
      country: 'Germany',
      isOnline: false,
    },
    broadcast: [
      {
        network: 'Twitch',
        channel: 'ESLCSGO',
      },
    ],
    bestOf: 5,
    tournament: {
      name: 'ESL One Cologne 2025',
      round: 'Grand Finals',
    },
    prizePool: '$1,000,000',
    viewerCount: 500000,
  },
  {
    id: '1',
    status: EventStatus.LIVE,
    startTime: '2025-01-18T19:00:00Z',
    game: {
      id: 'lol',
      name: 'League of Legends',
      abbreviation: 'LoL',
    },
    division: {
      id: 'premier',
      name: 'Premier Division',
      tier: 'Tier 1',
    },
    teams: {
      team1: {
        id: 'tl',
        name: 'Team Liquid',
        abbreviation: 'TL',
        record: '12-2',
        score: '2',
        isWinner: true,
      },
      team2: {
        id: 'c9',
        name: 'Cloud9',
        abbreviation: 'C9',
        record: '10-4',
        score: '1',
      },
    },
    venue: {
      name: 'Online Arena',
      isOnline: true,
    },
    broadcast: [
      {
        network: 'Twitch',
        channel: 'CarouselGG',
      },
    ],
    tournament: {
      name: 'Winter Championship 2025',
      round: 'Semi-Finals',
      bracketType: 'Upper Bracket',
    },
    bestOf: 5,
    prizePool: '$50,000',
    viewerCount: 15000,
  },
  {
    id: '3',
    status: EventStatus.LIVE,
    startTime: '2025-01-18T20:00:00Z',
    game: {
      id: 'ow',
      name: 'Overwatch',
      abbreviation: 'OW',
    },
    division: {
      id: 'elite',
      name: 'Elite Division',
      tier: 'Tier 1',
    },
    teams: {
      team1: {
        id: 'dyn',
        name: 'Dallas Dynasty',
        abbreviation: 'DYN',
        record: '14-1',
        score: '1',
      },
      team2: {
        id: 'atl',
        name: 'Atlanta Reign',
        abbreviation: 'ATL',
        record: '12-3',
        score: '1',
      },
    },
    venue: {
      name: 'Blizzard Arena',
      city: 'Irvine',
      country: 'USA',
      isOnline: false,
    },
    broadcast: [
      {
        network: 'Twitch',
        channel: 'OverwatchLeague',
      },
    ],
    bestOf: 5,
    tournament: {
      name: 'Overwatch Pro Circuit',
      round: 'Quarter-Finals',
    },
    prizePool: '$20,000',
    viewerCount: 12000,
  },
  {
    id: '2',
    status: EventStatus.SCHEDULED,
    startTime: '2025-01-18T21:30:00Z',
    game: {
      id: 'val',
      name: 'Valorant',
      abbreviation: 'VAL',
    },
    division: {
      id: 'amateur',
      name: 'Amateur Division',
    },
    teams: {
      team1: {
        id: 'sen',
        name: 'Sentinels',
        abbreviation: 'SEN',
        record: '8-4',
      },
      team2: {
        id: 'opt',
        name: 'OpTic Gaming',
        abbreviation: 'OPT',
        record: '7-5',
      },
    },
    venue: {
      name: 'Gaming Arena',
      city: 'Los Angeles',
      country: 'USA',
      isOnline: false,
    },
    broadcast: [
      {
        network: 'YouTube',
        channel: 'CarouselValorant',
      },
    ],
    bestOf: 3,
  },
  {
    id: '6',
    status: EventStatus.SCHEDULED,
    startTime: '2025-01-18T22:00:00Z',
    game: {
      id: 'apex',
      name: 'Apex Legends',
      abbreviation: 'APEX',
    },
    division: {
      id: 'open',
      name: 'Open Division',
    },
    teams: {
      team1: {
        id: 'tsm',
        name: 'TSM',
        abbreviation: 'TSM',
        record: '6-2',
        score: '0',
      },
      team2: {
        id: 'lg',
        name: 'Luminosity',
        abbreviation: 'LG',
        record: '7-1',
        score: '1',
      },
    },
    venue: {
      name: 'LAN Event Center',
      city: 'Seattle',
      country: 'USA',
      isOnline: false,
    },
    broadcast: [
      {
        network: 'Twitch',
        channel: 'ApexLegends',
      },
    ],
    bestOf: 3,
  },
  {
    id: '5',
    status: EventStatus.SCHEDULED,
    startTime: '2025-01-19T00:00:00Z',
    game: {
      id: 'rl',
      name: 'Rocket League',
      abbreviation: 'RL',
    },
    division: {
      id: 'challenger',
      name: 'Challenger Division',
    },
    teams: {
      team1: {
        id: 'gen',
        name: 'Gen.G',
        abbreviation: 'GEN',
        record: '10-2',
      },
      team2: {
        id: 'v1',
        name: 'Version1',
        abbreviation: 'V1',
        record: '9-3',
      },
    },
    venue: {
      name: 'Online Arena',
      isOnline: true,
    },
    broadcast: [
      {
        network: 'YouTube',
        channel: 'RocketLeague',
      },
    ],
    bestOf: 7,
    tournament: {
      name: 'RLCS Winter Invitational',
      round: 'Finals',
    },
    prizePool: '$100,000',
  },
  {
    id: '7',
    status: EventStatus.SCHEDULED,
    startTime: '2025-01-19T02:00:00Z',
    game: {
      id: 'lol',
      name: 'League of Legends',
      abbreviation: 'LoL',
    },
    division: {
      id: 'academy',
      name: 'Academy Division',
    },
    teams: {
      team1: {
        id: 'dig',
        name: 'Dignitas',
        abbreviation: 'DIG',
        record: '6-6',
      },
      team2: {
        id: 'imt',
        name: 'Immortals',
        abbreviation: 'IMT',
        record: '5-7',
      },
    },
    venue: {
      name: 'Online Arena',
      isOnline: true,
    },
    broadcast: [
      {
        network: 'Twitch',
        channel: 'CarouselAcademy',
      },
    ],
    bestOf: 3,
  },
  {
    id: '8',
    status: EventStatus.SCHEDULED,
    startTime: '2025-01-19T02:00:00Z',
    game: {
      id: 'lol',
      name: 'League of Legends',
      abbreviation: 'LoL',
    },
    division: {
      id: 'academy',
      name: 'Academy Division',
    },
    teams: {
      team1: {
        id: 'dig',
        name: 'Dignitas',
        abbreviation: 'DIG',
        record: '6-6',
      },
      team2: {
        id: 'imt',
        name: 'Immortals',
        abbreviation: 'IMT',
        record: '5-7',
      },
    },
    venue: {
      name: 'Online Arena',
      isOnline: true,
    },
    broadcast: [
      {
        network: 'Twitch',
        channel: 'CarouselAcademy',
      },
    ],
    bestOf: 3,
  },
  {
    id: '9',
    status: EventStatus.SCHEDULED,
    startTime: '2025-01-19T02:00:00Z',
    game: {
      id: 'lol',
      name: 'League of Legends',
      abbreviation: 'LoL',
    },
    division: {
      id: 'academy',
      name: 'Academy Division',
    },
    teams: {
      team1: {
        id: 'dig',
        name: 'Dignitas',
        abbreviation: 'DIG',
        record: '6-6',
      },
      team2: {
        id: 'imt',
        name: 'Immortals',
        abbreviation: 'IMT',
        record: '5-7',
      },
    },
    venue: {
      name: 'Online Arena',
      isOnline: true,
    },
    broadcast: [
      {
        network: 'Twitch',
        channel: 'CarouselAcademy',
      },
    ],
    bestOf: 3,
  },
  {
    id: '10',
    status: EventStatus.SCHEDULED,
    startTime: '2025-01-19T02:00:00Z',
    game: {
      id: 'lol',
      name: 'League of Legends',
      abbreviation: 'LoL',
    },
    division: {
      id: 'academy',
      name: 'Academy Division',
    },
    teams: {
      team1: {
        id: 'dig',
        name: 'Dignitas',
        abbreviation: 'DIG',
        record: '6-6',
      },
      team2: {
        id: 'imt',
        name: 'Immortals',
        abbreviation: 'IMT',
        record: '5-7',
      },
    },
    venue: {
      name: 'Online Arena',
      isOnline: true,
    },
    broadcast: [
      {
        network: 'Twitch',
        channel: 'CarouselAcademy',
      },
    ],
    bestOf: 3,
  },
  {
    id: '11',
    status: EventStatus.SCHEDULED,
    startTime: '2025-01-19T04:00:00Z',
    game: {
      id: 'dota2',
      name: 'Dota 2',
      abbreviation: 'DOTA2',
    },
    division: {
      id: 'pro',
      name: 'Pro Division',
    },
    teams: {
      team1: {
        id: 'eg',
        name: 'Evil Geniuses',
        abbreviation: 'EG',
        record: '12-4',
      },
      team2: {
        id: 'og',
        name: 'OG',
        abbreviation: 'OG',
        record: '11-5',
      },
    },
    venue: {
      name: 'International Arena',
      city: 'Singapore',
      country: 'Singapore',
      isOnline: false,
    },
    broadcast: [
      {
        network: 'Twitch',
        channel: 'Dota2Championship',
      },
    ],
    bestOf: 3,
  },
  {
    id: '12',
    status: EventStatus.SCHEDULED,
    startTime: '2025-01-19T06:30:00Z',
    game: {
      id: 'smash',
      name: 'Super Smash Bros.',
      abbreviation: 'SSB',
    },
    division: {
      id: 'open',
      name: 'Open Division',
    },
    teams: {
      team1: {
        id: 'mkleo',
        name: 'MKLeo',
        abbreviation: 'MKL',
        record: '15-1',
        score: '2',
      },
      team2: {
        id: 'tweek',
        name: 'Tweek',
        abbreviation: 'TWK',
        record: '14-2',
        score: '2',
      },
    },
    venue: {
      name: 'Gaming Con Center',
      city: 'Dallas',
      country: 'USA',
      isOnline: false,
    },
    broadcast: [
      {
        network: 'YouTube',
        channel: 'SmashLeague',
      },
    ],
    bestOf: 5,
  },
  {
    id: '13',
    status: EventStatus.SCHEDULED,
    startTime: '2025-01-19T08:00:00Z',
    game: {
      id: 'halo',
      name: 'Halo Infinite',
      abbreviation: 'HALO',
    },
    division: {
      id: 'elite',
      name: 'Elite Division',
    },
    teams: {
      team1: {
        id: 'g2',
        name: 'G2 Esports',
        abbreviation: 'G2',
        record: '9-3',
      },
      team2: {
        id: 'envy',
        name: 'Envy',
        abbreviation: 'ENV',
        record: '10-2',
      },
    },
    venue: {
      name: 'LAN Center',
      city: 'Toronto',
      country: 'Canada',
      isOnline: false,
    },
    broadcast: [
      {
        network: 'Twitch',
        channel: 'HaloChampionship',
      },
    ],
    bestOf: 5,
  },
  {
    id: '14',
    status: EventStatus.SCHEDULED,
    startTime: '2025-01-18T23:00:00Z',
    game: {
      id: 'rl',
      name: 'Rocket League',
      abbreviation: 'RL',
    },
    division: {
      id: 'collegiate',
      name: 'Collegiate Division',
    },
    teams: {
      team1: {
        id: 'msu',
        name: 'Michigan State University',
        abbreviation: 'MSU',
        record: '6-1',
        score: '3',
        isWinner: true,
      },
      team2: {
        id: 'osu',
        name: 'Ohio State University',
        abbreviation: 'OSU',
        record: '5-2',
        score: '2',
      },
    },
    venue: {
      name: 'Online Arena',
      isOnline: true,
    },
    broadcast: [
      {
        network: 'YouTube',
        channel: 'RLCollegiate',
      },
    ],
    bestOf: 5,
  },
];

export { sampleEvents };
