import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AuthState } from '../state/auth.state'; // Import your local AuthState
import { initialAuthState } from '../state/auth.state'; // Import your local initialState

type SetAuthPayload = Omit<AuthState, 'isAuthenticated'>;

const authSlice = createSlice({
  name: 'auth',
  initialState: initialAuthState,
  reducers: {
    setAuth: (state, action: PayloadAction<SetAuthPayload>) => {
      state.profile = action.payload.profile;
      state.idToken = action.payload.idToken;
      state.accessToken = action.payload.accessToken;
      state.isAuthenticated = true;
      state.loading = false;
    },
    clearAuth: (state) => {
      state.profile = null;
      state.idToken = null;
      state.accessToken = null;
      state.isAuthenticated = false;
      state.loading = false;
    },
    updateProfile: (state, action: PayloadAction<AuthState['profile']>) => {
      state.profile = action.payload;
    },
  },
});

const { actions, reducer } = authSlice;

const { setAuth, clearAuth, updateProfile } = actions;

export { setAuth, clearAuth, updateProfile };
export { reducer as authReducer };
