import { createSlice } from '@reduxjs/toolkit';
import {
  disableOrganization,
  fetchOrganizationByKebabCasedName,
  updateOrganizationDetails,
} from '../../services/admin/admin.service';
import { errorHandler } from '../../utils/error-handler.util';
import { Organization } from '@carousel/nexus';
import { initialAdminState } from '../state/admin.state';

const adminSlice = createSlice({
  name: 'admin',
  initialState: initialAdminState,
  reducers: {},
  extraReducers: (builder) => {
    /* Fetch Organization by Name */
    builder.addCase(fetchOrganizationByKebabCasedName.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fetchOrganizationByKebabCasedName.fulfilled,
      (state, action) => {
        state.loading = false;
        if (action.payload.isActive) {
          state.organization = action.payload as Organization;
        } else {
          state.organization = null;
        }
        state.error = null;
      },
    );
    builder.addCase(
      fetchOrganizationByKebabCasedName.rejected,
      (state, action) => {
        state.loading = false;
        state.organization = null;
        state.error = errorHandler(action.error);
      },
    );

    /* Update Organization */
    builder.addCase(updateOrganizationDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateOrganizationDetails.fulfilled, (state, action) => {
      state.loading = false;
      state.organization = action.payload as Organization;
      state.error = null;
    });
    builder.addCase(updateOrganizationDetails.rejected, (state, action) => {
      state.loading = false;
      state.error = errorHandler(action.error);
    });

    /* Disable Organization */
    builder.addCase(disableOrganization.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(disableOrganization.fulfilled, (state) => {
      state.loading = false;
      state.organization = null;
      state.error = null;
    });
    builder.addCase(disableOrganization.rejected, (state, action) => {
      state.loading = false;
      state.error = errorHandler(action.error);
    });
  },
});

const { actions, reducer } = adminSlice;

const {} = actions;

export {};
export { reducer as adminReducer };
