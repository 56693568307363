import { createContext, useContext, useEffect, useRef } from 'react';
import { useAppSelector } from '../store/hooks';
import {
  darkModeColors,
  lightModeColors,
  type ColorScheme,
} from '@carousel/nexus';
import { useDispatch } from 'react-redux';
import { setIsSideNavDoorOpen } from '../store/slices/layout.slice';

type LayoutContextType = {
  isSideNavOpen: boolean;
  setIsSideNavDoorOpen: (isOpen: boolean) => void;
  colors: ColorScheme;
};

const LayoutContext = createContext<LayoutContextType | undefined>(undefined);

export const LayoutProvider = ({ children }: { children: React.ReactNode }) => {
  const dispatch = useDispatch();
  const isMobile = useAppSelector((state) => state.layout.isMobile);
  const isDarkMode = useAppSelector((state) => state.layout.isDarkMode);
  const isSideNavOpen = useAppSelector((state) => state.layout.isSideNavOpen);

  const colors = isDarkMode ? darkModeColors : lightModeColors;

  const initialRender = useRef(true);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      return;
    }
    dispatch(setIsSideNavDoorOpen(!isMobile));
  }, [isMobile, dispatch]);

  const value = {
    isSideNavOpen,
    setIsSideNavDoorOpen,
    colors,
  };

  return (
    <LayoutContext.Provider value={value}>{children}</LayoutContext.Provider>
  );
};

export const useLayout = () => {
  const context = useContext(LayoutContext);
  if (context === undefined) {
    throw new Error('useLayout must be used within a LayoutProvider');
  }
  return context;
};
