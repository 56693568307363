import { Division } from '@carousel/nexus';
import { CarouselError } from '../../types/errors/carousel-error.type';

type DivisionState = {
  loading: boolean;
  error: CarouselError | null;
  divisions: Division[];
  selected: Division | null;
};

const initialDivisionsState: DivisionState = {
  loading: false,
  error: null,
  divisions: [],
  selected: null,
};

export { initialDivisionsState };
export type { DivisionState };
