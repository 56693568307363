import { ColorScheme } from '@/types';

const lightModeColors: ColorScheme = {
  bg: {
    primary: 'bg-slate-50',
    secondary: 'bg-white',
    tertiary: 'bg-slate-100',
  },
  text: {
    base: 'text-slate-900',
    muted: 'text-slate-500',
    accent1: 'text-blue-600',
    accent2: 'text-rose-600',
    accent3: 'text-violet-600',
  },
  button: {
    base: 'bg-sky-600 hover:bg-sky-700',
  },
  border: {
    primary: { class: 'border-slate-50', color: '#f8fafc' },
    secondary: { class: 'border-white', color: '#ffffff' },
    tertiary: { class: 'border-slate-100', color: '#f1f5f9' },
    accent1: { class: 'border-blue-600', color: '#2563eb' },
    accent2: { class: 'border-rose-600', color: '#e11d48' },
    accent3: { class: 'border-violet-600', color: '#7c3aed' },
  },
};

export { lightModeColors };
