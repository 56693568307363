import { useAuth0 } from '@auth0/auth0-react';
import { UserCircle, LogIn, LogOut, UserPlus } from 'lucide-react';
import { useState, useRef, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { useAppSelector } from '../../../store/hooks';
import { ColorScheme, Text } from '@carousel/nexus';
import { RootState } from '../../../store/store';
import { Link } from 'react-router-dom';
import {
  AuthOrganizationRoles,
  AuthRole,
} from '../../../store/state/auth.state';

type UserMenuProps = {
  colors: ColorScheme;
};

const UserMenu = ({ colors }: UserMenuProps) => {
  const { loginWithRedirect, logout } = useAuth0();
  const { profile, isAuthenticated } = useAppSelector(
    (state: RootState) => state.auth,
  );
  const orgRoles = useAppSelector(
    (state: RootState) => state.auth.profile?.org_roles,
  );

  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target as Node) &&
        !buttonRef.current?.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const getDropdownPosition = () => {
    if (!buttonRef.current) return { top: 0, right: 0 };
    const rect = buttonRef.current.getBoundingClientRect();
    return {
      top: rect.bottom + 8,
      right: window.innerWidth - rect.right,
    };
  };

  const { top, right } = getDropdownPosition();

  return (
    <div className="relative">
      {/* Profile Button */}
      <button
        ref={buttonRef}
        onClick={() => setIsOpen(!isOpen)}
        className={`relative rounded-full overflow-hidden w-10 h-10 flex items-center justify-center transition-all 
          ${isOpen ? 'ring-2 ring-sky-400' : 'hover:ring-2 hover:ring-sky-300/50'}`}
      >
        {isAuthenticated && profile?.picture ? (
          <img
            src={profile.picture}
            alt={profile.name || 'User profile'}
            className="w-full h-full object-cover"
          />
        ) : (
          <UserCircle className={`w-full h-full ${colors.text.base}`} />
        )}
      </button>

      {/* Dropdown Menu Portal */}
      {isOpen &&
        createPortal(
          <div
            ref={menuRef}
            className={`fixed w-56 rounded-xl shadow-lg 
            ${colors.bg.secondary} border border-gray-700/50 backdrop-blur-sm`}
            style={{
              top: `${top}px`,
              right: `${right}px`,
              zIndex: 100,
            }}
          >
            <div role="menu" aria-orientation="vertical">
              {isAuthenticated ? (
                <>
                  {profile?.name && (
                    <div className={`px-3 py-2 mb-1 ${colors.text.base}`}>
                      <div className="font-medium">{profile.name}</div>
                      <div className="text-sm opacity-75">{profile.email}</div>
                    </div>
                  )}
                  <div className="h-px bg-gray-700/50 my-1" />

                  {/* Organization Roles Section */}
                  {orgRoles && orgRoles.length > 0 && (
                    <>
                      {orgRoles.map((org: AuthOrganizationRoles) => (
                        <div key={org.id}>
                          <div
                            className={`px-3 py-2 text-sm ${colors.text.accent3}`}
                          >
                            <Text size="sm" weight="bold">
                              {org.display_name}
                            </Text>
                          </div>
                          {org.roles
                            .filter(
                              (role: AuthRole) =>
                                role.name === 'Organization Member',
                            )
                            .map((role: AuthRole) => (
                              <Link
                                key={role.id}
                                to={`/org/${org.name}`}
                                className={`flex items-center w-full px-7 py-1 text-sm
                                  ${colors.text.base} hover:${colors.text.accent1} transition-colors
                                  `}
                                onClick={() => setIsOpen(false)}
                              >
                                View
                              </Link>
                            ))}
                          {org.roles
                            .filter(
                              (role: AuthRole) =>
                                role.name === 'Organization Admin',
                            )
                            .map((role: AuthRole) => (
                              <Link
                                key={role.id}
                                to={`/manage/org/${org.name}`}
                                className={`flex items-center w-full px-7 py-1 text-sm
                                  ${colors.text.base} hover:${colors.text.accent1} transition-colors
                                  `}
                                onClick={() => setIsOpen(false)}
                              >
                                Manage
                              </Link>
                            ))}
                          <div className="h-px border-t border-dotted border-gray-700/50 my-1" />
                        </div>
                      ))}
                    </>
                  )}

                  <button
                    onClick={() => {
                      setIsOpen(false);
                      logout({
                        logoutParams: { returnTo: window.location.origin },
                      });
                    }}
                    className={`flex items-center w-full px-3 py-2 text-sm rounded-lg
                    ${colors.text.base} hover:${colors.text.accent1} transition-colors gap-2
                    hover:bg-gray-700/20`}
                    role="menuitem"
                  >
                    <LogOut size={16} />
                    <span>Sign out</span>
                  </button>
                </>
              ) : (
                <>
                  <button
                    onClick={() => {
                      setIsOpen(false);
                      loginWithRedirect({
                        authorizationParams: { screen_hint: 'signup' },
                      });
                    }}
                    className={`flex items-center w-full px-3 py-2 text-sm rounded-lg
                    ${colors.text.base} hover:${colors.text.accent1} transition-colors gap-2
                    hover:bg-gray-700/20 mb-1`}
                    role="menuitem"
                  >
                    <UserPlus size={16} />
                    <span>Sign up</span>
                  </button>
                  <button
                    onClick={() => {
                      setIsOpen(false);
                      loginWithRedirect();
                    }}
                    className={`flex items-center w-full px-3 py-2 text-sm rounded-lg
                    ${colors.text.base} hover:${colors.text.accent1} transition-colors gap-2
                    hover:bg-gray-700/20`}
                    role="menuitem"
                  >
                    <LogIn size={16} />
                    <span>Sign in</span>
                  </button>
                </>
              )}
            </div>
          </div>,
          document.body,
        )}
    </div>
  );
};

export { UserMenu };
export type { UserMenuProps };
