import { Organization } from '@carousel/nexus';
import { CarouselError } from '../../types/errors/carousel-error.type';

type AdminState = {
  loading: boolean;
  error: CarouselError | null;
  organization: Organization | null;
};

const initialAdminState: AdminState = {
  loading: false,
  error: null,
  organization: null,
};

export { initialAdminState };
export type { AdminState };
