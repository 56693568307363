import { NavItem } from '../../types/layout/nav-item.type';
import { NavigationType } from '../../types/layout/navigation-type.enum';

type LayoutState = {
  isDarkMode: boolean;
  isMobile: boolean;
  isSideNavOpen: boolean;
  screenWidth: number;
  navItems: NavItem[];
  navigationType: NavigationType;
};

const initialLayoutState: LayoutState = {
  isDarkMode: true,
  isSideNavOpen: window.innerWidth < 768 ? false : true,
  isMobile: window.innerWidth < 768,
  screenWidth: window.innerWidth,
  navItems: [],
  navigationType: NavigationType.HOME,
};

// Breakpoint constants (matching Tailwind defaults)
const BREAKPOINTS = {
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  '2xl': 1536,
} as const;

export { initialLayoutState, BREAKPOINTS };
export type { LayoutState };
