import { createSlice } from '@reduxjs/toolkit';
import { initialOrganizationState } from '../state/organization.state';
import { fetchOrganizationByKebabCasedName } from '../../services/organization/organization.service';
import { errorHandler } from '../../utils/error-handler.util';
import { Organization } from '@carousel/nexus';

const organizationSlice = createSlice({
  name: 'organization',
  initialState: initialOrganizationState,
  reducers: {},
  extraReducers: (builder) => {
    /* Fetch Organization by Name */
    builder.addCase(fetchOrganizationByKebabCasedName.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fetchOrganizationByKebabCasedName.fulfilled,
      (state, action) => {
        state.loading = false;
        if (action.payload.isActive) {
          state.selected = action.payload as Organization;
        } else {
          state.selected = null;
        }
        state.error = null;
      },
    );
    builder.addCase(
      fetchOrganizationByKebabCasedName.rejected,
      (state, action) => {
        state.loading = false;
        state.selected = null;
        state.error = errorHandler(action.error);
      },
    );
  },
});

const { actions, reducer } = organizationSlice;

const {} = actions;

export {};
export { reducer as organizationReducer };
