import { configureStore, combineReducers } from '@reduxjs/toolkit';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { adminReducer } from './slices/admin.slice';
import { authReducer } from './slices/auth.slice';
import { bannersReducer } from './slices/banners.slice';
import { divisionReducer } from './slices/division.slice';
import { layoutReducer } from './slices/layout.slice';
import { newsReducer } from './slices/news.slice';
import { organizationReducer } from './slices/organization.slice';
import { subDivisionReducer } from './slices/sub-division.slice';

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  whitelist: ['layout'],
  blacklist: ['auth'],
};

const rootReducer = combineReducers({
  admin: adminReducer,
  auth: authReducer,
  banners: bannersReducer,
  division: divisionReducer,
  layout: layoutReducer,
  news: newsReducer,
  organization: organizationReducer,
  subDivision: subDivisionReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

const persistor = persistStore(store);

type RootState = ReturnType<typeof store.getState>;
type AppDispatch = typeof store.dispatch;

export { store, persistor };
export type { RootState, AppDispatch };
