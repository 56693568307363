import {
  memo,
  useEffect,
  useState,
  useCallback,
  useRef,
  Suspense,
} from 'react';
import { Outlet, useLocation, useParams } from 'react-router-dom';
import { SideNav } from './side-nav/side-nav.component';
import { useLayout } from '../../contexts/layout.context';
import { Nav } from './nav/nav.component';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { setNavigationType } from '../../store/slices/layout.slice';
import { NavigationType } from '../../types/layout/navigation-type.enum';
import { LoadingSpinner } from '@carousel/nexus';
import { Bounce, ToastContainer } from 'react-toastify';

const HomeLayoutBase = () => {
  const { colors } = useLayout();
  const dispatch = useDispatch();
  const location = useLocation();
  const { kebabCasedOrganizationName } = useParams<{
    kebabCasedOrganizationName?: string;
  }>();
  const isDarkMode = useSelector((state: RootState) => state.layout.isDarkMode);
  const isMobile = useSelector((state: RootState) => state.layout.isMobile);

  const navContainerRef = useRef<HTMLDivElement>(null);
  const [isSideNavOpen, setIsSideNavOpen] = useState(() => !isMobile);

  useEffect(() => {
    const isOrgRoute = location.pathname.startsWith('/org/');
    const isManageOrgRoute = location.pathname.startsWith('/manage/org/');

    if (isManageOrgRoute && kebabCasedOrganizationName) {
      dispatch(
        setNavigationType({
          type: NavigationType.MANAGE_ORGANIZATION,
          orgName: kebabCasedOrganizationName,
        }),
      );
    } else if (isOrgRoute && kebabCasedOrganizationName) {
      dispatch(
        setNavigationType({
          type: NavigationType.ORGANIZATION,
          orgName: kebabCasedOrganizationName,
        }),
      );
    } else {
      dispatch(
        setNavigationType({
          type: NavigationType.HOME,
        }),
      );
    }
  }, [location, kebabCasedOrganizationName, dispatch]);

  useEffect(() => {
    setIsSideNavOpen(!isMobile);
  }, [isMobile]);

  const handleSideNavToggle = useCallback((newState: boolean) => {
    setIsSideNavOpen(newState);
  }, []);

  useEffect(() => {
    const bodyElement = document.body;
    const primaryClass = colors.bg.primary.split(' ')[0];
    const computedStyle = window.getComputedStyle(
      document.querySelector(`.${primaryClass}`) || document.body,
    );
    const actualColor = computedStyle.backgroundColor;
    bodyElement.style.backgroundColor = actualColor;

    return () => {
      bodyElement.style.backgroundColor = '';
    };
  }, [colors.bg.primary, isDarkMode]);

  return (
    <div
      className={`min-h-screen w-full ${colors.bg.primary} ${colors.text.base} relative overflow-hidden`}
    >
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        style={{ top: `${isMobile ? '80px' : '124px'}` }}
        draggable
        pauseOnHover
        theme={isDarkMode ? 'dark' : 'light'}
        transition={Bounce}
      />
      <div
        ref={navContainerRef}
        className={`w-full ${colors.bg.secondary} relative z-40`}
      >
        <Nav
          colors={colors}
          onSideNavToggle={handleSideNavToggle}
          isSideNavOpen={isSideNavOpen}
        />
      </div>

      <SideNav
        colors={colors}
        isOpen={isSideNavOpen}
        isMobile={isMobile}
        onClose={() => setIsSideNavOpen(false)}
        navContainerRef={navContainerRef}
      />

      <main
        className={`relative z-10 transition-all duration-300 ease-in-out
          ${isMobile ? 'pt-24' : 'pt-36'} 
          ${!isMobile && isSideNavOpen ? 'pl-40' : 'pl-0'}`}
      >
        <Suspense fallback={<LoadingSpinner colors={colors} />}>
          <Outlet context={{ isMobile, colors }} />
        </Suspense>
      </main>
    </div>
  );
};

export const HomeLayout = memo(HomeLayoutBase);
HomeLayout.displayName = 'HomeLayout';
