import { Globe } from 'lucide-react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import { ColorScheme, EventStatus, ScheduledEvent } from '@carousel/nexus';
import { RootState } from '../../../store/store';
import { useAppSelector } from '../../../store/hooks';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';

type EventsBarProps = {
  colors: ColorScheme;
  events: ScheduledEvent[];
};

const EventsBar = ({ colors, events }: EventsBarProps) => {
  const isMobile = useAppSelector((state: RootState) => state.layout.isMobile);
  const isSideNavOpen = useAppSelector(
    (state: RootState) => state.layout.isSideNavOpen,
  );

  return (
    <div
      className={`fixed left-0 right-0 z-50 ${isMobile ? 'top-[74px]' : 'top-[108px]'}`}
    >
      <style>
        {`
          .events-container {
            position: relative;
            padding: 0 46px;
          }

          .swiper.events-swiper {
            height: 104px;
            position: static;
          }

          .events-swiper .swiper-button-next,
          .events-swiper .swiper-button-prev {
            width: 46px;
            height: 46px;
            border-radius: 50%;
            background: none;
            color: rgb(209, 213, 219);
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            z-index: 60;
          }

          .events-swiper .swiper-button-prev {
            left: 0;
            top: 72px;
          }

          .events-swiper .swiper-button-next {
            right: 0;
            top: 72px;
          }

          .events-swiper .swiper-button-next:hover,
          .events-swiper .swiper-button-prev:hover {
            color: rgb(255, 255, 255);
          }

          .events-swiper .swiper-button-next:after,
          .events-swiper .swiper-button-prev:after {
            font-size: 14px;
          }
        `}
      </style>

      <div
        className={`relative transition-all duration-300 ease-in-out
        ${!isMobile && isSideNavOpen ? 'ml-[150px]' : 'ml-0'}`}
      >
        {/* Events section */}
        <div className={`w-full ${colors.bg.primary}`}>
          <div className="events-container">
            <Swiper
              modules={[Navigation]}
              spaceBetween={12}
              slidesPerView="auto"
              navigation
              className="events-swiper"
            >
              {events.map((event) => (
                <SwiperSlide key={event.id} style={{ width: '180px' }}>
                  <div
                    className={`${colors.bg.primary} rounded p-2 h-[90px] cursor-pointer border
                      hover:ring-1 hover:ring-opacity-50 hover:ring-pink-500 transition-all duration-200 mt-[7px]`}
                  >
                    <div className="flex items-center justify-between">
                      <span className={`text-[11px] ${colors.text.accent3}`}>
                        {event.division.name}
                      </span>
                      <span
                        className={`text-[11px] ${
                          event.status === EventStatus.LIVE
                            ? 'text-red-500 font-bold'
                            : colors.text.base
                        }`}
                      >
                        {event.status === EventStatus.LIVE
                          ? 'LIVE'
                          : new Date(event.startTime).toLocaleTimeString([], {
                              hour: 'numeric',
                              minute: '2-digit',
                            })}
                      </span>
                    </div>

                    <div className="mt-1.5 space-y-1">
                      {[event.teams.team1, event.teams.team2].map((team) => (
                        <div
                          key={team.id}
                          className="flex items-center justify-between"
                        >
                          <div className="flex items-center gap-1.5 min-w-0">
                            <span
                              className={`text-xs ${colors.text.base} ${
                                team.isWinner ? 'font-bold' : ''
                              }`}
                            >
                              {team.abbreviation}
                            </span>
                            <span className="text-[10px] text-gray-400">
                              {team.record}
                            </span>
                          </div>
                          {team.score !== undefined && (
                            <span
                              className={`text-xs ${
                                team.isWinner
                                  ? colors.text.accent2 + ' font-bold'
                                  : colors.text.base
                              }`}
                            >
                              {team.score}
                            </span>
                          )}
                        </div>
                      ))}
                    </div>

                    <div className="flex justify-between items-center mt-2">
                      <span className="text-[10px] text-gray-400">
                        {event.broadcast[0]?.network}
                      </span>
                      <div className="flex items-center gap-0.5">
                        <Globe className="w-2 h-2 text-gray-400" />
                        <span className="text-[10px] text-gray-400">
                          {event.venue?.isOnline ? 'Online' : event.venue?.city}
                        </span>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  );
};

export { EventsBar };
