import { useOutletContext } from 'react-router-dom';
import { ColorScheme, Heading, Text } from '@carousel/nexus';
import { Home } from 'lucide-react';
import { Link } from 'react-router-dom';

type OutletContext = {
  colors: ColorScheme;
};

const Error403 = () => {
  const { colors } = useOutletContext<OutletContext>();

  return (
    <div className={`${colors.bg.primary} min-h-screen`}>
      <div className="p-4 pt-32">
        <div className="text-center max-w-xl mx-auto">
          <div className="w-16 h-16 mx-auto mb-6 rounded-full bg-purple-500/20 flex items-center justify-center">
            <span className="text-4xl">!</span>
          </div>

          <Heading size="h1" className="text-pink-500 mb-4">
            Forbidden
          </Heading>

          <Text size="lg" className={`${colors.text.base} mb-8`}>
            You do not have permission to access this page.
          </Text>

          <Link
            to="/"
            className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-3 px-8 rounded-lg 
              transform transition duration-200 hover:scale-105 flex items-center gap-2 mx-auto w-fit"
          >
            <Home className="w-4 h-4" />
            Respawn at Nexus
          </Link>
        </div>
      </div>
    </div>
  );
};

export { Error403 };
