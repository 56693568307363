import {
  forwardRef,
  ComponentRef,
  ComponentPropsWithoutRef,
  createContext,
  useContext,
} from 'react';
import * as SelectPrimitive from '@radix-ui/react-select';
import { ChevronDown } from 'lucide-react';
import { cn } from '../../lib/utils';
import { ColorScheme } from '@/types';

type SelectContextType = {
  colors: ColorScheme;
};

const SelectContext = createContext<SelectContextType | null>(null);

const useSelect = () => {
  const context = useContext(SelectContext);
  if (!context) {
    throw new Error('useSelect must be used within a Select');
  }
  return context;
};

interface SelectRootProps extends SelectPrimitive.SelectProps {
  colors: ColorScheme;
}

const Select = ({ colors, children, ...props }: SelectRootProps) => (
  <SelectContext.Provider value={{ colors }}>
    <SelectPrimitive.Root {...props}>{children}</SelectPrimitive.Root>
  </SelectContext.Provider>
);

const SelectGroup = SelectPrimitive.Group;
const SelectValue = SelectPrimitive.Value;

const SelectTrigger = forwardRef<
  ComponentRef<typeof SelectPrimitive.Trigger>,
  ComponentPropsWithoutRef<typeof SelectPrimitive.Trigger> & {
    open?: boolean;
  }
>(({ className, children, open, ...props }, ref) => {
  const { colors } = useSelect();
  return (
    <SelectPrimitive.Trigger
      ref={ref}
      className={cn(
        'flex h-10 w-full items-center justify-between px-3 py-2 text-sm',
        `${colors.bg.secondary}`,
        `${colors.text}`,
        'focus:outline-none focus:ring-2',
        `focus:ring-${colors.text.accent3}`,
        'disabled:cursor-not-allowed disabled:opacity-50',
        open ? 'rounded-t-md' : 'rounded-md',
        className,
      )}
      {...props}
    >
      <div className="flex-1 text-center">{children}</div>
      <SelectPrimitive.Icon asChild>
        <ChevronDown
          className={`h-4 w-4 opacity-50 flex-shrink-0 ${colors.text.accent3}`}
        />
      </SelectPrimitive.Icon>
    </SelectPrimitive.Trigger>
  );
});
SelectTrigger.displayName = SelectPrimitive.Trigger.displayName;

const SelectContent = forwardRef<
  ComponentRef<typeof SelectPrimitive.Content>,
  ComponentPropsWithoutRef<typeof SelectPrimitive.Content>
>(({ className, children, position = 'popper', ...props }, ref) => {
  const { colors } = useSelect();
  return (
    <SelectPrimitive.Portal>
      <SelectPrimitive.Content
        ref={ref}
        className={cn(
          'relative z-50 min-w-[8rem] w-full overflow-hidden',
          `${colors.bg.secondary}`,
          'text-sm shadow-md',
          'data-[state=open]:animate-in data-[state=closed]:animate-out',
          'data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0',
          'data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95',
          'data-[side=bottom]:slide-in-from-top-2',
          className,
        )}
        position={position}
        {...props}
        style={{
          width: 'var(--radix-select-trigger-width)',
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          borderBottomLeftRadius: '0.375rem',
          borderBottomRightRadius: '0.375rem',
        }}
      >
        <SelectPrimitive.Viewport>{children}</SelectPrimitive.Viewport>
      </SelectPrimitive.Content>
    </SelectPrimitive.Portal>
  );
});

SelectContent.displayName = SelectPrimitive.Content.displayName;

const SelectItem = forwardRef<
  ComponentRef<typeof SelectPrimitive.Item>,
  ComponentPropsWithoutRef<typeof SelectPrimitive.Item>
>(({ className, children, ...props }, ref) => {
  const { colors } = useSelect();
  return (
    <SelectPrimitive.Item
      ref={ref}
      className={cn(
        'relative flex w-full select-none items-center px-3 py-2 text-sm outline-none cursor-pointer',
        `${colors.text.base}`,
        'data-[disabled]:pointer-events-none data-[disabled]:opacity-50',
        `hover:${colors.bg.primary} hover:bg-opacity-80`,
        `data-[state=checked]:${colors.bg.tertiary}`,
        className,
      )}
      {...props}
    >
      <SelectPrimitive.ItemText>{children}</SelectPrimitive.ItemText>
    </SelectPrimitive.Item>
  );
});

SelectItem.displayName = SelectPrimitive.Item.displayName;

export {
  Select,
  SelectGroup,
  SelectValue,
  SelectTrigger,
  SelectContent,
  SelectItem,
};
