import { createSlice } from '@reduxjs/toolkit';
import { initialDivisionsState } from '../state/division.state';
import { Division } from '@carousel/nexus';

const divisionSlice = createSlice({
  name: 'diviison',
  initialState: initialDivisionsState,
  reducers: {
    setDivisionsForOrganization: (state, action) => {
      state.divisions = action.payload as Division[];
    },
    selectDivisionsForOrganization: (state, action) => {
      state.divisions = action.payload.divisions as Division[];
    },
    setSelectedDivision: (state, action) => {
      state.selected = action.payload as Division;
    },
  },
});

const { actions, reducer } = divisionSlice;

const {
  selectDivisionsForOrganization,
  setDivisionsForOrganization,
  setSelectedDivision,
} = actions;

export {
  selectDivisionsForOrganization,
  setDivisionsForOrganization,
  setSelectedDivision,
};
export { reducer as divisionReducer };
