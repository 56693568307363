import { Organization } from '@carousel/nexus';
import { CarouselError } from '../../types/errors/carousel-error.type';

type OrganizationState = {
  loading: boolean;
  error: CarouselError | null;
  selected: Organization | null;
};

const initialOrganizationState: OrganizationState = {
  loading: false,
  error: null,
  selected: null,
};

export { initialOrganizationState };
export type { OrganizationState };
