import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

const Error404 = lazy(() =>
  import('../views/session/error-404.component').then((module) => ({
    default: module.Error404,
  })),
);

const sessionRoutes: RouteObject[] = [
  {
    path: '/*',
    element: <Error404 />,
  },
];

export { sessionRoutes };
