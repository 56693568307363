import { Sun, Moon } from 'lucide-react';
import { UserMenu } from './user-menu.component';
import { FullScreenButton } from './full-screen-button.component';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { toggleDarkMode } from '../../../store/slices/layout.slice';
import { SideNavToggle } from '../side-nav/side-nav-toggle.component';
import { ColorScheme } from '@carousel/nexus';
import { SearchDropdown } from './search-dropdown.component';

import logo from '../../../assets/logos/carousel/logo.png';
import { RootState } from '../../../store/store';

type NavProps = {
  colors: ColorScheme;
  isSideNavOpen: boolean;
  onSideNavToggle: (isOpen: boolean) => void;
};

const Nav = ({ colors, isSideNavOpen, onSideNavToggle }: NavProps) => {
  const dispatch = useAppDispatch();

  const isDarkMode = useAppSelector(
    (state: RootState) => state.layout.isDarkMode,
  );

  const isMobile = useAppSelector((state: RootState) => state.layout.isMobile);

  return (
    <div className="relative w-full">
      <nav
        className={`fixed top-0 w-full ${isMobile ? 'py-4 px-5' : 'p-7'} 
          flex justify-between items-center shadow-lg z-10 
          ${colors.bg.primary} ${colors.text.base}`}
      >
        {/* Left section */}
        <div
          className={`flex items-center gap-2 ${isMobile ? 'w-1/3' : 'w-1/4'}`}
        >
          <a className="flex items-center space-x-2" href="/">
            <img
              src={logo}
              alt="Arcade Logo"
              className={`${isMobile ? 'h-8 w-8' : 'h-12 w-12'}`}
            />
          </a>
          <SideNavToggle
            colors={colors}
            isOpen={isSideNavOpen}
            onToggle={onSideNavToggle}
          />
        </div>

        {/* Center section - Search Bar */}
        <div className={`${isMobile ? 'w-1/2.25' : 'w-2/5'} max-w-xl`}>
          <SearchDropdown colors={colors} />
        </div>

        {/* Right section */}
        <div
          className={`flex items-center ${isMobile ? 'gap-2 w-1/3' : 'gap-4 w-1/4'} justify-end`}
        >
          {!isMobile && <FullScreenButton colors={colors} />}

          <button
            onClick={() => dispatch(toggleDarkMode())}
            className={`${isMobile ? 'p-1' : 'p-2'} rounded-full ${colors.text.base} hover:opacity-80`}
            aria-label={
              isDarkMode ? 'Switch to light mode' : 'Switch to dark mode'
            }
          >
            {isDarkMode ? (
              <Sun size={isMobile ? 20 : 24} />
            ) : (
              <Moon size={isMobile ? 20 : 24} />
            )}
          </button>
          <UserMenu colors={colors} />
        </div>
      </nav>

      {/* Drop shadow overlay */}
      <div className="absolute -bottom-6 left-0 right-0 h-6 bg-gradient-to-b from-primary/10 to-transparent pointer-events-none z-0" />
    </div>
  );
};

export { Nav };
export type { NavProps };
