import { Outlet, useOutletContext, useParams } from 'react-router-dom';
import { useFetchOrganizationByKebabCasedName } from '../../hooks/organization/use-fetch-organization-by-kebab-cased-name.hook';
import { ColorScheme, LoadingSpinner } from '@carousel/nexus';
import { ErrorPage } from './errors/errors.component';
import { memo, Suspense } from 'react';
import { OrganizationTitle } from './title/organization-title.component';
import { EventsBar } from './events/events-bar.component';

// temporarily put this here until we make api call
import { sampleEvents } from './events-bar.data';
import { DivisionSubDivisionSelection } from './components/division-sub-division-selection.component';
import { Error404 } from '../session/error-404.component';

type OrganizationParams = {
  kebabCasedOrganizationName: string;
};

type OutletContext = {
  colors: ColorScheme;
  isMobile: boolean;
  isSideNavOpen: boolean;
};

const OrganizationBase = () => {
  const { colors, isMobile } = useOutletContext<OutletContext>();
  const { kebabCasedOrganizationName } = useParams<OrganizationParams>();

  const { loading, organization, error } = useFetchOrganizationByKebabCasedName(
    kebabCasedOrganizationName || '',
  );

  if (loading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return <ErrorPage error={error} />;
  }

  if (!organization || organization.isActive === false) {
    return <Error404 />;
  }

  if (organization) {
    return (
      <div className={`${colors.bg.primary} min-h-screen px-8 flex flex-col`}>
        <EventsBar colors={colors} events={sampleEvents} />
        <DivisionSubDivisionSelection
          colors={colors}
          isMobile={isMobile}
          organization={organization}
        />
        <OrganizationTitle
          name={organization?.name}
          colors={colors}
          isMobile={isMobile}
        />

        <Suspense fallback={<LoadingSpinner />}>
          <Outlet context={{ organization, colors }} />
        </Suspense>
      </div>
    );
  }
};

export const Organization = memo(OrganizationBase);
Organization.displayName = 'Organization';
