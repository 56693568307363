import { SerializedError } from '@reduxjs/toolkit';
import { CarouselError } from '../types/errors/carousel-error.type';

type AxiosError = Error & {
  code?: string;
  response?: {
    status: number;
  };
};

const STATUS_MAPPINGS: Record<string, number> = {
  // HTTP Status Codes
  '400': 400,
  '401': 401,
  '403': 403,
  '404': 404,
  '429': 429,
  '500': 500,
  '503': 503,

  // Named Errors
  ValidationError: 400,
  AuthenticationError: 401,
  RateLimitExceeded: 429,
  NetworkError: 503,

  // Axios Error Codes
  ERR_BAD_REQUEST: 400,
  ERR_UNAUTHORIZED: 401,
  ERR_FORBIDDEN: 403,
  ERR_NOT_FOUND: 404,
  ERR_TIMEOUT: 408,
  ERR_NETWORK: 503,
  ERR_SERVER_ERROR: 500,
};

const extractStatusFromMessage = (message: string): number | null => {
  const statusMatch = message.match(/status code (\d{3})/);
  if (statusMatch && statusMatch[1]) {
    const status = parseInt(statusMatch[1], 10);
    return status >= 100 && status < 600 ? status : null;
  }
  return null;
};

const getStatusFromMappings = (key: string | undefined): number => {
  if (key && key in STATUS_MAPPINGS) {
    return STATUS_MAPPINGS[key];
  }
  return 500;
};

const errorHandler = (error: SerializedError | Error): CarouselError => {
  const defaultError: CarouselError = {
    message: 'An unknown error occurred.',
    status: 500,
  };

  // Handle message
  if ('message' in error && error.message) {
    defaultError.message = error.message;

    // Try to extract status from error message
    const messageStatus = extractStatusFromMessage(error.message);
    if (messageStatus) {
      defaultError.status = messageStatus;
      return defaultError;
    }
  }

  // Handle Axios errors
  if (error instanceof Error) {
    const axiosError = error as AxiosError;

    // Check for response status
    if (axiosError.response?.status) {
      defaultError.status = axiosError.response.status;
      return defaultError;
    }

    // Check error code
    if (axiosError.code) {
      defaultError.status = getStatusFromMappings(axiosError.code);
      return defaultError;
    }

    // Check error name
    defaultError.status = getStatusFromMappings(axiosError.name);
  }

  // Handle Redux Toolkit serialized errors
  if ('code' in error && error.code) {
    defaultError.status = getStatusFromMappings(error.code);
  }

  return defaultError;
};

export { errorHandler };
