import { ColorScheme } from '@carousel/nexus';
import { Menu } from 'lucide-react';
import { RootState } from '../../../store/store';
import { useAppSelector } from '../../../store/hooks';
import { useDispatch } from 'react-redux';
import { setIsSideNavDoorOpen } from '../../../store/slices/layout.slice';

type SideNavToggleProps = {
  colors: ColorScheme;
  isOpen: boolean;
  onToggle: (isOpen: boolean) => void;
};

const SideNavToggle = ({ colors, isOpen, onToggle }: SideNavToggleProps) => {
  const dispatch = useDispatch();
  const isMobile = useAppSelector((state: RootState) => state.layout.isMobile);

  const handleToggle = (e: React.MouseEvent) => {
    e.stopPropagation();
    onToggle(!isOpen);
    dispatch(setIsSideNavDoorOpen(!isOpen));
  };

  return (
    <button
      onClick={handleToggle}
      className={`${isMobile ? 'p-1' : 'p-2'} rounded-lg 
        ${colors.text.base} hover:${colors.text.accent1} 
        transition-all duration-300 ease-in-out transform`}
      aria-label={isOpen ? 'Close navigation menu' : 'Open navigation menu'}
    >
      <Menu
        size={24}
        className={`transition-transform duration-300 ease-in-out transform
          ${isOpen ? '-rotate-180' : 'rotate-0'}`}
      />
    </button>
  );
};

export { SideNavToggle };
export type { SideNavToggleProps };
