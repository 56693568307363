import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ColorScheme, Heading, Text } from '@carousel/nexus';
import {
  AlertTriangle,
  Home,
  RefreshCw,
  ArrowLeft,
  Lock,
  Ban,
  Clock,
  ServerCrash,
} from 'lucide-react';
import { useOutletContext } from 'react-router-dom';
import { CarouselError } from '../../../types/errors/carousel-error.type';

type OutletContext = {
  colors: ColorScheme;
};

type ErrorPageProps = {
  error: CarouselError;
};

const getErrorContent = (status: number) => {
  switch (status) {
    case 400:
      return {
        title: 'Invalid Request',
        description:
          'The request was malformed or contained invalid parameters.',
        icon: AlertTriangle,
      };
    case 401:
      return {
        title: 'Authentication Required',
        description: 'Please sign in to access this organization.',
        icon: Lock,
      };
    case 403:
      return {
        title: 'Access Denied',
        description: "You don't have permission to access this organization.",
        icon: Ban,
      };
    case 404:
      return {
        title: 'Organization Not Found',
        description: "We couldn't find the organization you're looking for.",
        icon: AlertTriangle,
      };
    case 429:
      return {
        title: 'Too Many Requests',
        description: 'Please wait a moment before trying again.',
        icon: Clock,
      };
    case 503:
      return {
        title: 'Service Unavailable',
        description:
          'Our service is temporarily unavailable. Please try again later.',
        icon: ServerCrash,
      };
    default:
      return {
        title: 'Something Went Wrong',
        description: 'An unexpected error occurred.',
        icon: AlertTriangle,
      };
  }
};

const ErrorPage = ({ error }: ErrorPageProps) => {
  const { colors } = useOutletContext<OutletContext>();
  const errorContent = getErrorContent(error.status);
  const ErrorIcon = errorContent.icon;

  useEffect(() => {
    console.error('Organization Error:', error);
  }, [error]);

  const getTroubleshootingSteps = (status: number) => {
    const commonSteps = [
      'Check your internet connection',
      'Try refreshing the page',
      'Clear your browser cache',
    ];

    switch (status) {
      case 401:
        return ['Sign in to your account', 'Check if your session has expired'];
      case 403:
        return [
          'Verify your account permissions',
          'Contact the organization administrator',
        ];
      case 404:
        return [
          'Check the organization URL for typos',
          'The organization may have changed their name',
          'The organization may no longer be active',
        ];
      default:
        return commonSteps;
    }
  };

  return (
    <div className={`${colors.bg.primary} min-h-screen p-4`}>
      <div className="max-w-2xl mx-auto mt-16 md:mt-24">
        <div
          className={`${colors.bg.secondary} w-full rounded-xl shadow-xl p-8 md:p-12`}
        >
          <div className="text-center mb-8">
            <ErrorIcon
              className={`${colors.text.accent3} w-16 h-16 mx-auto mb-6`}
            />
            <Heading size="h1" className={`${colors.text.accent2} mb-4`}>
              {errorContent.title}
            </Heading>
            <Text size="lg" className={`${colors.text.base} mb-8`}>
              {errorContent.description}
            </Text>

            <div className="text-left mb-8">
              <Text
                size="base"
                className={`${colors.text.accent2} font-semibold mb-4`}
              >
                Try these steps:
              </Text>
              <ul className={`${colors.text.base} space-y-3`}>
                {getTroubleshootingSteps(error.status).map((step, index) => (
                  <li key={index} className="flex items-start gap-2">
                    <span className="min-w-4">•</span>
                    {step}
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div className="flex flex-col sm:flex-row gap-4 justify-center">
            <Link
              to="/"
              className={`${colors.button.base} text-white font-semibold py-3 px-6 rounded-lg
                shadow-lg transform transition duration-200 hover:scale-105 flex items-center 
                justify-center gap-2`}
            >
              <Home className="w-4 h-4" />
              Return Home
            </Link>
            <button
              onClick={() => window.location.reload()}
              className={`${colors.bg.secondary} border-2 border-current ${colors.text.base} 
                font-semibold py-3 px-6 rounded-lg shadow-lg transform transition 
                duration-200 hover:scale-105 flex items-center justify-center gap-2`}
            >
              <RefreshCw className="w-4 h-4" />
              Try Again
            </button>
          </div>

          <div className="mt-8 pt-8 border-t border-gray-200">
            <Text size="sm" className={`${colors.text.base} text-center`}>
              Error ID: {error.status} - If this issue persists, please contact
              support.
            </Text>
            <button
              onClick={() => window.history.back()}
              className={`${colors.text.base} flex items-center gap-2 mx-auto mt-4 
                hover:underline text-sm`}
            >
              <ArrowLeft className="w-4 h-4" />
              Go Back
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export { ErrorPage };
