type User = {
  sub: string;
  email?: string;
  email_verified?: boolean;
  name?: string;
  nickname?: string;
  given_name?: string;
  family_name?: string;
  locale?: string;
  updated_at?: string;
  [key: string]: unknown;
};

type AuthState = {
  profile: {
    email?: string;
    email_verified?: boolean;
    name?: string;
    nickname?: string;
    picture?: string;
    sub?: string;
    updated_at?: string;
    org_roles: AuthOrganizationRoles[];
    [key: string]: unknown;
  } | null;
  loading: boolean;
  user: User | null;
  idToken: string | null;
  accessToken: string | null;
  isAuthenticated: boolean;
};

type AuthOrganizationRoles = {
  id: string;
  display_name: string;
  name: string;
  roles: AuthRole[];
};

type AuthRole = {
  id: string;
  name: string;
};

const initialAuthState: AuthState = {
  loading: true,
  profile: null,
  user: null,
  idToken: null,
  accessToken: null,
  isAuthenticated: false,
};

export { initialAuthState };
export type { AuthState, AuthRole, AuthOrganizationRoles, User };
