import { createSlice } from '@reduxjs/toolkit';
import { initialBannersState } from '../state/banners.state';
import { fetchImages } from '../../services/banners/banners.service';
import { errorHandler } from '../../utils/error-handler.util';
import { BannerItem } from '@carousel/nexus';

const bannersSlice = createSlice({
  name: 'banners',
  initialState: initialBannersState,
  reducers: {},
  extraReducers: (builder) => {
    /* Fetch Images */
    builder.addCase(fetchImages.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchImages.fulfilled, (state, action) => {
      state.loading = false;
      state.items = action.payload as BannerItem[] | [];
      state.error = null;
    });
    builder.addCase(fetchImages.rejected, (state, action) => {
      state.loading = false;
      state.items = [];
      state.error = errorHandler(action.error);
    });
  },
});

const { actions, reducer } = bannersSlice;

const {} = actions;

export {};
export { reducer as bannersReducer };
