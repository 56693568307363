import { createAsyncThunk } from '@reduxjs/toolkit';
import axios, { AxiosRequestConfig } from 'axios';

import * as changeCase from 'change-case';
import { Organization } from '@carousel/nexus';
import { UpdateOrganizationDetailsRequestBody } from './types/update-organization-details-request-body.type';

const baseUrl = import.meta.env.VITE_BFF_BASE_URL || 'https://bff.carousel.gg';

const fetchOrganizationByKebabCasedName = createAsyncThunk(
  'admin/fetchLeagueByKebabCasedName',
  async (name: string): Promise<Organization> => {
    const config: AxiosRequestConfig = {
      params: {
        name: changeCase.kebabCase(name),
        inactiveDivisions: false,
        inactiveSubDivisions: false,
      },
    };

    const response = await axios.get(
      `${baseUrl}/organization/${changeCase.kebabCase(name)}`,
      config,
    );

    if (response.status !== 200) {
      throw new Error('Error fetching organization by name');
    }
    return response.data as Organization;
  },
);

const updateOrganizationDetails = createAsyncThunk(
  'admin/updateOrganizationDetails',
  async ({
    id,
    organization,
  }: {
    id: string;
    organization: UpdateOrganizationDetailsRequestBody;
  }): Promise<Organization> => {
    const data = {
      name: organization.name,
      code: organization.code,
    };
    const response = await axios.patch(
      `${baseUrl}/organization/${id}/details`,
      data,
    );

    if (response.status !== 200) {
      throw new Error('Error updating organization details');
    }
    return response.data.updatedItem as Organization;
  },
);

const disableOrganization = createAsyncThunk(
  'admin/disableOrganization',
  async ({ id }: { id: string }): Promise<Organization> => {
    const response = await axios.patch(`${baseUrl}/organization/${id}/disable`);

    if (response.status !== 200) {
      throw new Error('Error disabling organization');
    }
    return response.data.updatedItem as Organization;
  },
);

export {
  disableOrganization,
  fetchOrganizationByKebabCasedName,
  updateOrganizationDetails,
};
