import { ColorScheme } from '@carousel/nexus';
import { Link, useLocation } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { useAppSelector } from '../../../store/hooks';
import { Bell } from 'lucide-react';
import { mapNavigationItemIcon } from '../../../utils/map-navigation-item-icon.util';

type SideNavProps = {
  colors: ColorScheme;
  isOpen: boolean;
  isMobile: boolean;
  onClose: () => void;
  navContainerRef: React.RefObject<HTMLDivElement | null>;
};

type SideNavItem = {
  label: string;
  path: string;
  icon: typeof Bell;
};

const SideNav = ({
  colors,
  isOpen,
  isMobile,
  onClose,
  navContainerRef,
}: SideNavProps) => {
  const location = useLocation();
  const navRef = useRef<HTMLElement>(null);
  const [navItems, setNavItems] = useState<SideNavItem[]>([]);
  const stateNavItems = useAppSelector((state) => state.layout.navItems);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const isClickingNav = navRef.current?.contains(event.target as Node);
      const isClickingHeader = navContainerRef?.current?.contains(
        event.target as Node,
      );

      if (isMobile && isOpen && !isClickingNav && !isClickingHeader) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [isMobile, isOpen, onClose, navContainerRef]);

  useEffect(() => {
    const items: SideNavItem[] = stateNavItems.map((item) => ({
      label: item.label,
      path: item.path,
      icon: mapNavigationItemIcon(item.iconName),
    }));

    setNavItems(items);
  }, [stateNavItems]);

  return (
    <>
      {/* Overlay backdrop for mobile - positioned below nav */}
      {isMobile && isOpen && (
        <div
          className={`fixed inset-0 ${isMobile ? 'top-[64px]' : 'top-[104px]'} bg-black/50 z-30`}
          aria-hidden="true"
        />
      )}

      <nav
        ref={navRef}
        className={`fixed left-0 ${isMobile ? 'top-[64px]' : 'top-[104px]'} bottom-0 w-[150px] transform transition-transform duration-300 ease-in-out 
    ${isOpen ? 'translate-x-0' : '-translate-x-full'} 
    ${colors.bg.primary}
    ${isMobile ? 'z-30' : 'z-20'}
    shadow-[4px_0_8px_-4px_rgba(0,0,0,0.15)]
    flex flex-col`}
      >
        {/* Scrollable navigation items container */}
        <div className="flex-1 overflow-y-auto pb-[65px]">
          {' '}
          {/* Added pb-[65px] to ensure space for help button */}
          <div className="flex flex-col gap-2 p-2 w-full">
            {navItems.map((item, index) => {
              const Icon = item.icon;
              const isActive = location.pathname === item.path;

              return (
                <div key={item.path} className="w-full">
                  <Link
                    to={item.path}
                    onClick={() => isMobile && onClose()}
                    className={`w-full flex flex-col items-center justify-center h-[110px]
                      relative overflow-hidden group ${colors.text.base} rounded-lg
                      transform hover:scale-[1.02] active:scale-[0.98]
                      ${isActive ? colors.text.accent3 : `hover:${colors.text.accent3}`}`}
                  >
                    <div
                      className={`absolute inset-0 bg-gradient-to-r from-violet-500/0 via-violet-500/5 to-purple-500/0 
                      transition-opacity duration-200 ${isActive ? 'opacity-100' : 'opacity-0 group-hover:opacity-100'}`}
                    />

                    <div
                      className={`absolute right-0 top-0 bottom-0 w-1 bg-violet-400 
                      transform transition-transform duration-200 ${isActive ? 'translate-x-0' : 'translate-x-full group-hover:translate-x-0'}`}
                    />

                    <div className="relative z-10 flex flex-col items-center gap-2">
                      <Icon
                        size={28}
                        className="transition-colors duration-200"
                      />
                      <span className="text-base font-medium text-inherit text-center">
                        {item.label}
                      </span>
                    </div>
                  </Link>

                  {index < navItems.length - 1 && (
                    <div className="h-px bg-gray-700/30 mt-2 w-full" />
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </nav>
    </>
  );
};

export { SideNav };
export type { SideNavProps };
