import { createAsyncThunk } from '@reduxjs/toolkit';
import axios, { AxiosRequestConfig } from 'axios';

import * as changeCase from 'change-case';
import { Organization } from '@carousel/nexus';

const baseUrl = import.meta.env.VITE_BFF_BASE_URL || 'https://bff.carousel.gg';

const fetchOrganizationByKebabCasedName = createAsyncThunk(
  'organization/fetchLeagueByKebabCasedName',
  async (name: string): Promise<Organization> => {
    const config: AxiosRequestConfig = {
      params: {
        name: changeCase.kebabCase(name),
        inactiveDivisions: false,
        inactiveSubDivisions: false,
      },
    };

    const response = await axios.get(
      `${baseUrl}/organization/${changeCase.kebabCase(name)}`,
      config,
    );

    if (response.status !== 200) {
      throw new Error('Error fetching organization by name');
    }
    return response.data as Organization;
  },
);

export { fetchOrganizationByKebabCasedName };
