import { lazy, memo } from 'react';
import { RouteObject } from 'react-router-dom';
import { Organization } from '../views/organization/organization.component';

const News = memo(
  lazy(() =>
    import('../views/organization/news/news.component').then((module) => ({
      default: module.OrganizationNews,
    })),
  ),
);

const Info = memo(
  lazy(() =>
    import('../views/organization/info/info.component').then((module) => ({
      default: module.OrganizationInfo,
    })),
  ),
);

const Error404 = memo(
  lazy(() =>
    import('../views/session/error-404.component').then((module) => ({
      default: module.Error404,
    })),
  ),
);

const organizationRoutes: RouteObject[] = [
  {
    path: 'org/:kebabCasedOrganizationName',
    Component: Organization,
    children: [
      {
        path: '',
        element: <Info />,
      },
      {
        path: 'news',
        element: <News />,
      },
      {
        path: '*',
        element: <Error404 />,
      },
    ],
  },
];

export { organizationRoutes };
