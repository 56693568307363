import { SubDivision } from '@carousel/nexus';
import { CarouselError } from '../../types/errors/carousel-error.type';

type SubDivisionState = {
  loading: boolean;
  error: CarouselError | null;
  subDivisions: SubDivision[];
  selected: SubDivision | null;
};

const initialSubDivisionsState: SubDivisionState = {
  loading: false,
  error: null,
  subDivisions: [],
  selected: null,
};

export { initialSubDivisionsState };
export type { SubDivisionState };
