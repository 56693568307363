type EventTeam = {
  id: string;
  name: string;
  abbreviation: string;
  record?: string;
  seed?: number;
  score?: string;
  isWinner?: boolean;
};

type EventBroadcast = {
  network: string; // e.g., "Twitch", "YouTube", "ESPN+"
  channel?: string; // e.g., specific channel name/number
  url?: string; // streaming link
};

type EventTournament = {
  name: string; // e.g., "Winter Championship"
  round?: string; // e.g., "Quarter Finals"
  bracketType?: string; // e.g., "Upper Bracket", "Lower Bracket"
};

type ScheduledEvent = {
  id: string;
  status: EventStatus;
  startTime: string; // ISO date string
  game: {
    id: string;
    name: string; // e.g., "League of Legends", "Valorant"
    abbreviation?: string;
  };
  division: {
    id: string;
    name: string; // e.g., "Premier", "Amateur"
    tier?: string; // e.g., "Tier 1", "Tier 2"
  };
  teams: {
    team1: EventTeam;
    team2: EventTeam;
  };
  venue?: {
    name: string;
    city?: string;
    country?: string;
    isOnline: boolean;
  };
  broadcast: EventBroadcast[];
  tournament?: EventTournament;
  bestOf?: number; // e.g., 3, 5, 7
  prizePool?: string; // e.g., "$10,000"
  viewerCount?: number; // for live events
};

enum EventStatus {
  SCHEDULED = 'scheduled',
  LIVE = 'live',
  FINISHED = 'finished',
  POSTPONED = 'postponed',
  CANCELLED = 'cancelled',
}

export type { ScheduledEvent, EventTeam, EventBroadcast, EventTournament };
export { EventStatus };
