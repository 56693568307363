import { useOutletContext, useNavigate, useLocation } from 'react-router-dom';
import { ColorScheme, Heading, Text } from '@carousel/nexus';
import { Home, ArrowLeft } from 'lucide-react';

type OutletContext = {
  colors: ColorScheme;
};

const Error404 = () => {
  const { colors } = useOutletContext<OutletContext>();
  const navigate = useNavigate();
  const location = useLocation();

  // Check if we have a previous location in the state
  const canGoBack = location.key !== 'default';

  const handleNavigation = () => {
    if (canGoBack) {
      navigate(-1); // Go back to previous page if we have history
    } else {
      navigate('/'); // Go to home if no history
    }
  };

  return (
    <div className={`${colors.bg.primary} min-h-screen`}>
      <div className="p-4 pt-32">
        <div className="text-center max-w-xl mx-auto">
          <div className="w-16 h-16 mx-auto mb-6 rounded-full bg-purple-500/20 flex items-center justify-center">
            <span className="text-4xl">!</span>
          </div>

          <Heading size="h1" className="text-pink-500 mb-4">
            Page Not Found
          </Heading>

          <Text size="lg" className={`${colors.text} mb-8`}>
            Looks like you&apos;ve hit the wrong spawn point! The page
            you&apos;re looking for doesn&apos;t exist or might have been moved.
          </Text>

          <button
            onClick={handleNavigation}
            className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-3 px-8 rounded-lg 
              transform transition duration-200 hover:scale-105 flex items-center gap-2 mx-auto"
          >
            {canGoBack ? (
              <>
                <ArrowLeft className="w-4 h-4" />
                Go Back
              </>
            ) : (
              <>
                <Home className="w-4 h-4" />
                Respawn at Nexus
              </>
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export { Error404 };
