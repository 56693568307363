import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Organization } from '@carousel/nexus';

import { AppDispatch, RootState } from '../../store/store';
import { fetchOrganizationByKebabCasedName } from '../../services/organization/organization.service';
import { CarouselError } from '../../types/errors/carousel-error.type';

const useFetchOrganizationByKebabCasedName = (
  name: string,
): {
  organization: Organization | null;
  loading: boolean;
  error: CarouselError | null;
} => {
  const dispatch = useDispatch<AppDispatch>();
  const organization = useSelector(
    (state: RootState) => state.organization.selected,
  );
  const loading = useSelector((state: RootState) => state.organization.loading);
  const error = useSelector((state: RootState) => state.organization.error);

  useEffect(() => {
    dispatch(fetchOrganizationByKebabCasedName(name));
  }, [dispatch, name]);

  return { organization, loading, error };
};

export { useFetchOrganizationByKebabCasedName };
