import { Outlet, useOutletContext, useParams } from 'react-router-dom';
import { useFetchOrganizationByKebabCasedName } from '../../hooks/admin/use-fetch-organization-by-kebab-cased-name.hook';
import { ColorScheme, LoadingSpinner } from '@carousel/nexus';
import { ErrorPage } from './errors/errors.component';
import { memo, Suspense } from 'react';
import { AuthGuard } from './auth/auth-guard.component';
import { Error404 } from '../session/error-404.component';
import { cn } from '@carousel/nexus';

type OrganizationParams = {
  kebabCasedOrganizationName: string;
};

type OutletContext = {
  colors: ColorScheme;
  isMobile: boolean;
};

const ManageOrganizationBase = () => {
  const { colors, isMobile } = useOutletContext<OutletContext>();
  const { kebabCasedOrganizationName } = useParams<OrganizationParams>();

  const { loading, organization, error } = useFetchOrganizationByKebabCasedName(
    kebabCasedOrganizationName || '',
  );

  if (loading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return <ErrorPage error={error} />;
  }

  if (!organization || organization.isActive === false) {
    return <Error404 />;
  }

  if (organization) {
    return (
      <div
        className={cn(
          `${colors.bg.primary} min-h-screen flex flex-col`,
          isMobile ? 'p-0' : 'p-8',
        )}
      >
        <AuthGuard organization={organization}>
          <Suspense fallback={<LoadingSpinner />}>
            <Outlet context={{ organization, colors }} />
          </Suspense>
        </AuthGuard>
      </div>
    );
  }
};

export const ManageOrganization = memo(ManageOrganizationBase);
ManageOrganization.displayName = 'Organization';
