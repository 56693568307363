import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { initialLayoutState, BREAKPOINTS } from '../state/layout.state';
import { NavigationType } from '../../types/layout/navigation-type.enum';
import { HOME_NAV_ITEMS } from '../../layout/navigation-items/home-navigation-items';
import { getOrgNavItems } from '../../layout/navigation-items/organization-navigation-items';
import { getManageOrgNavItems } from '../../layout/navigation-items/manage-organization-navigation-items';

interface NavigationTypePayload {
  type: NavigationType;
  orgName?: string;
}

const layoutSlice = createSlice({
  name: 'layout',
  initialState: initialLayoutState,
  reducers: {
    setIsSideNavDoorOpen: (state, action) => {
      state.isSideNavOpen = action.payload;
    },
    toggleDarkMode: (state) => {
      state.isDarkMode = !state.isDarkMode;
    },
    setDarkMode: (state, action: PayloadAction<boolean>) => {
      state.isDarkMode = action.payload;
    },
    setNavigationType: (
      state,
      action: PayloadAction<NavigationTypePayload>,
    ) => {
      state.navigationType = action.payload.type;
      switch (action.payload.type as NavigationType) {
        case NavigationType.HOME:
          state.navItems = HOME_NAV_ITEMS;
          break;
        case NavigationType.ORGANIZATION:
          state.navItems = getOrgNavItems(action.payload.orgName as string);
          break;
        case NavigationType.MANAGE_ORGANIZATION:
          state.navItems = getManageOrgNavItems(
            action.payload.orgName as string,
          );
      }
    },
    updateScreenSize: (state, action: PayloadAction<number>) => {
      state.screenWidth = action.payload;
      state.isMobile = action.payload < BREAKPOINTS.md;
    },
  },
});

const { actions, reducer } = layoutSlice;

const {
  toggleDarkMode,
  setIsSideNavDoorOpen,
  setDarkMode,
  updateScreenSize,
  setNavigationType,
} = actions;

export {
  toggleDarkMode,
  setIsSideNavDoorOpen,
  setDarkMode,
  updateScreenSize,
  setNavigationType,
};
export { reducer as layoutReducer };
