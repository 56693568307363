import { IconName } from '../../types/layout/icon-item.type';
import { NavItem } from '../../types/layout/nav-item.type';

const HOME_NAV_ITEMS: NavItem[] = [
  {
    label: 'Home',
    path: '/',
    iconName: IconName.HOME,
  },
  {
    label: 'Play',
    path: '/play',
    iconName: IconName.GAMEPAD_2,
  },
  {
    label: 'About',
    path: '/about',
    iconName: IconName.BAR_CHART_2,
  },
  {
    label: 'How it Works',
    path: '/how-it-works',
    iconName: IconName.SETTINGS,
  },
  {
    label: 'Help',
    path: '/help',
    iconName: IconName.HELP_CIRCLE,
  },
];

export { HOME_NAV_ITEMS };
