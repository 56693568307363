import { useEffect, useCallback, useRef } from 'react';
import { useAppDispatch } from '../store/hooks';
import { updateScreenSize } from '../store/slices/layout.slice';

const useScreenSize = () => {
  const dispatch = useAppDispatch();
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const handleResize = useCallback(() => {
    dispatch(updateScreenSize(window.innerWidth));
  }, [dispatch]);

  const debouncedHandleResize = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(handleResize, 150);
  }, [handleResize]);

  useEffect(() => {
    window.addEventListener('resize', debouncedHandleResize);

    // Initial size check
    handleResize();

    return () => {
      window.removeEventListener('resize', debouncedHandleResize);
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [debouncedHandleResize, handleResize]);
};

export { useScreenSize };
