// App.tsx
import { useRoutes } from 'react-router-dom';
import { Suspense, memo } from 'react';
import { Text, Heading } from '@carousel/nexus';
import './styles/globals.styles.css';
import { routes } from './routes/app.routes';
import { LayoutProvider } from './contexts/layout.context';
import { useAuthState } from './hooks/use-auth-state.hook';
import { useScreenSize } from './hooks/use-screen-size.hook';

const LoadingFallback = memo(() => (
  <div className="flex flex-col gap-4 items-center justify-center min-h-screen">
    <Heading size="h2" className="text-sky-300">
      Carousel
    </Heading>
    <Text size="lg" variant="muted" className="text-sky-300">
      Loading your gaming experience...
    </Text>
  </div>
));
LoadingFallback.displayName = 'LoadingFallback';

const RoutedContent = memo(() => {
  const element = useRoutes(routes);
  return element;
});
RoutedContent.displayName = 'RoutedContent';

const App = memo(() => {
  useAuthState();
  useScreenSize();

  return (
    <LayoutProvider>
      <div className="min-h-screen bg-black">
        <Suspense fallback={<LoadingFallback />}>
          <RoutedContent />
        </Suspense>
      </div>
    </LayoutProvider>
  );
});

App.displayName = 'App';
export { App };
