import { BannerItem } from '@carousel/nexus';
import { CarouselError } from '../../types/errors/carousel-error.type';

type BannersState = {
  loading: boolean;
  error: CarouselError | null;
  items: BannerItem[];
};

const initialBannersState: BannersState = {
  loading: false,
  error: null,
  items: [],
};

export { initialBannersState };
export type { BannersState };
