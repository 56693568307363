import * as React from 'react';
import { cva, type VariantProps } from 'class-variance-authority';
import { cn } from '../../lib/utils';

const textVariants = cva('text-base leading-relaxed', {
  variants: {
    variant: {
      default: 'text-foreground',
      muted: 'text-muted-foreground',
      link: 'text-primary hover:underline cursor-pointer',
    },
    size: {
      sm: 'text-sm',
      base: 'text-base',
      lg: 'text-lg',
      xl: 'text-xl',
    },
    weight: {
      normal: 'font-normal',
      medium: 'font-medium',
      bold: 'font-bold',
    },
  },
  defaultVariants: {
    variant: 'default',
    size: 'base',
    weight: 'normal',
  },
});

interface HeadingProps
  extends React.HTMLAttributes<HTMLHeadingElement>,
    VariantProps<typeof headingVariants> {
  as?: 'h1' | 'h2' | 'h3' | 'h4';
}

interface TextProps
  extends React.HTMLAttributes<HTMLParagraphElement>,
    VariantProps<typeof textVariants> {}

const headingVariants = cva(
  'text-skin-base font-bold leading-tight tracking-tighter',
  {
    variants: {
      size: {
        h1: 'text-4xl md:text-5xl uppercase', // Added uppercase here
        h2: 'text-3xl md:text-4xl uppercase', // And here
        h3: 'text-2xl md:text-3xl uppercase', // And here
        h4: 'text-xl md:text-2xl uppercase', // And here
        h5: 'text-lg md:text-xl uppercase', // And here
        h6: 'text-base md:text-lg uppercase', // And here
      },
    },
    defaultVariants: {
      size: 'h1',
    },
  },
);

const Heading = React.forwardRef<HTMLHeadingElement, HeadingProps>(
  ({ className, size, as = 'h1', ...props }, ref) => {
    const Component = as;
    return (
      <Component
        ref={ref}
        className={cn(headingVariants({ size, className }))}
        {...props}
      />
    );
  },
);

const Text = React.forwardRef<HTMLParagraphElement, TextProps>(
  ({ className, variant, size, weight, ...props }, ref) => {
    return (
      <p
        ref={ref}
        className={cn(textVariants({ variant, size, weight, className }))}
        {...props}
      />
    );
  },
);

Heading.displayName = 'Heading';
Text.displayName = 'Text';

export { Heading, Text, headingVariants, textVariants };
export type { HeadingProps, TextProps };
