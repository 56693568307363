import { useState, FormEvent, useRef, useEffect } from 'react';
import { Search, X } from 'lucide-react';
import { ColorScheme, Text } from '@carousel/nexus';
import { useAppSelector } from '../../../store/hooks';
import { useNavigate } from 'react-router-dom';

type PlayerStatus = 'looking for team' | 'contracted' | 'inactive';
type OrganizationStatus = 'recruiting' | 'roster set' | 'inactive';
type LeagueStatus =
  | 'registration open'
  | 'registration closed'
  | 'in season'
  | 'off season';

type StatusMap = {
  player: Record<PlayerStatus, string>;
  organization: Record<OrganizationStatus, string>;
  league: Record<LeagueStatus, string>;
};

type SearchResult = {
  id: string;
  kebabCasedName?: string;
  name: string;
  type: keyof StatusMap;
  status: PlayerStatus | OrganizationStatus | LeagueStatus;
};

type SearchDropdownProps = {
  colors: ColorScheme;
};
const getDarkModeColors = (): StatusMap => ({
  player: {
    'looking for team': 'bg-green-500/20 text-green-300',
    contracted: 'bg-sky-500/20 text-sky-300',
    inactive: 'bg-zinc-500/20 text-zinc-400',
  },
  organization: {
    recruiting: 'bg-green-500/20 text-green-300',
    'roster set': 'bg-sky-500/20 text-sky-300',
    inactive: 'bg-zinc-500/20 text-zinc-400',
  },
  league: {
    'registration open': 'bg-green-500/20 text-green-300',
    'in season': 'bg-sky-500/20 text-sky-300',
    'off season': 'bg-violet-500/20 text-violet-400',
    'registration closed': 'bg-pink-500/20 text-pink-400',
  },
});

const getLightModeColors = (): StatusMap => ({
  player: {
    'looking for team': 'bg-sky-100 text-sky-700',
    contracted: 'bg-green-100 text-green-700',
    inactive: 'bg-slate-100 text-slate-700',
  },
  organization: {
    recruiting: 'bg-violet-100 text-violet-700',
    'roster set': 'bg-sky-100 text-sky-700',
    inactive: 'bg-slate-100 text-slate-700',
  },
  league: {
    'registration open': 'bg-pink-100 text-pink-700',
    'registration closed': 'bg-pink-500/20 text-pink-400',
    'in season': 'bg-sky-100 text-sky-700',
    'off season': 'bg-violet-100 text-violet-700',
  },
});

const SearchDropdown = ({ colors }: SearchDropdownProps) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [results] = useState<SearchResult[]>([
    {
      id: '1',
      kebabCasedName: 'pandamonium-league',
      name: 'Pandamonium League',
      type: 'league',
      status: 'registration open',
    },
    { id: '2', name: 'Cloud9', type: 'organization', status: 'recruiting' },
    { id: '3', name: 'Shroud', type: 'player', status: 'contracted' },
    {
      id: '4',
      name: 'Team Liquid',
      type: 'organization',
      status: 'roster set',
    },
    { id: '5', name: 'NawBrah', type: 'player', status: 'looking for team' },
    { id: '6', name: 'FACEIT Pro League', type: 'league', status: 'in season' },
    {
      id: '7',
      name: 'Evil Geniuses',
      type: 'organization',
      status: 'inactive',
    },
    { id: '8', name: 'Summer Circuit', type: 'league', status: 'off season' },
  ]);

  const searchInputRef = useRef<HTMLInputElement>(null);

  const isDarkMode = useAppSelector((state) => state.layout.isDarkMode);
  const isMobile = useAppSelector((state) => state.layout.isMobile);
  const statusColors = isDarkMode ? getDarkModeColors() : getLightModeColors();

  const getStatusColor = (type: keyof StatusMap, status: string): string => {
    return (
      statusColors[type]?.[
        status as keyof (typeof statusColors)[typeof type]
      ] ||
      (isDarkMode
        ? 'bg-zinc-500/20 text-zinc-400'
        : 'bg-slate-100 text-slate-700')
    );
  };

  const handleSearch = (e: FormEvent) => {
    e.preventDefault();
    console.log('Search query:', searchQuery);
  };

  const openSearch = () => {
    setIsOpen(true);
    setTimeout(() => {
      if (searchInputRef.current) {
        searchInputRef.current.focus();
      }
    }, 100);
  };

  const closeSearch = () => {
    setSearchQuery('');
    setIsOpen(false);
  };

  const onSearchResultItemClick = (result: SearchResult) => {
    // Handle search result item click
    switch (result.type) {
      case 'player':
        console.log('Player clicked:', result);
        break;
      case 'organization':
      case 'league':
        navigate(`/org/${result.kebabCasedName}`);
        closeSearch();
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const handleEscape = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        closeSearch();
      }
    };

    if (isOpen) {
      document.addEventListener('keydown', handleEscape);
      document.body.style.overflow = 'hidden';
    }

    return () => {
      document.removeEventListener('keydown', handleEscape);
      document.body.style.overflow = '';
    };
  }, [isOpen]);

  return (
    <>
      {/* Regular search input in nav */}
      <div className="relative w-full">
        <form onSubmit={handleSearch} className="relative">
          <input
            type="text"
            placeholder={
              isMobile
                ? 'Search...'
                : 'Search leagues, organizations, players...'
            }
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            onFocus={openSearch}
            className={
              isMobile
                ? `w-full py-3 px-4 ${colors.bg.secondary} 
              outline-none transition-all duration-200`
                : `w-full py-3 px-4 ${colors.bg.secondary} 
              outline-none text-lg transition-all duration-200`
            }
          />
          <Search className="absolute right-3 top-1/2 transform -translate-y-1/2 w-4 h-4 text-gray-400" />
        </form>
      </div>

      {/* Full-screen overlay */}
      {isOpen && (
        <>
          {/* Base overlay */}
          <div className={`fixed inset-0 ${colors.bg.primary} z-[9999]`} />

          {/* Main content */}
          <div
            className={`fixed inset-0 z-[10000] flex flex-col ${colors.text.base}`}
          >
            {/* Close button in top right corner */}
            <div className="absolute top-8 right-8">
              <button
                onClick={closeSearch}
                className={`p-2 rounded-full hover:${colors.text.accent3} ${colors.text.base}
                  transition-all duration-200 hover:scale-105`}
              >
                <X className="w-8 h-8" />
              </button>
            </div>

            {/* Search header */}
            <div className={`w-full ${isMobile ? 'pt-[5vh]' : 'pt-[15vh]'} `}>
              <div className="px-4">
                <div className="max-w-3xl mx-auto">
                  <form onSubmit={handleSearch} className="relative">
                    <input
                      ref={searchInputRef}
                      type="text"
                      placeholder={
                        isMobile ? 'Search ...' : 'What are you looking for?'
                      }
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                      className={`w-full py-6 text-5xl font-light bg-transparent
                        outline-none border-none ${colors.text.base}
                        placeholder:text-gray-400
                        transition-all duration-200`}
                    />
                  </form>
                </div>
              </div>
            </div>

            {/* Search Results */}
            <div className="flex-1 overflow-y-auto p-4">
              <div className="w-full max-w-3xl mx-auto">
                {searchQuery.length > 0 &&
                  (results.length > 0 ? (
                    <div className="flex flex-col space-y-2">
                      {results.map((result) => (
                        <div
                          key={result.id}
                          onClick={() => onSearchResultItemClick(result)}
                          className={`${colors.bg.secondary} p-4 rounded-lg 
                            hover:ring-2 hover:ring-sky-500 cursor-pointer 
                            transition-all duration-150`}
                        >
                          <div className="flex items-center justify-between">
                            <div className="flex flex-col">
                              <Text
                                size="xl"
                                className={`${colors.text.accent2}`}
                              >
                                {result.name}
                              </Text>
                              <Text
                                size="sm"
                                className={`${colors.text.accent3}`}
                              >
                                {result.type}
                              </Text>
                            </div>
                            <Text
                              size="sm"
                              className={`px-3 py-1 rounded-full capitalize 
                                ${getStatusColor(result.type, result.status)}`}
                            >
                              {result.status}
                            </Text>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className="text-center py-12">
                      <p className={colors.text.base}>No results found</p>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export { SearchDropdown };
