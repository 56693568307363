import { ColorScheme } from '@/types';

const darkModeColors: ColorScheme = {
  bg: {
    primary: 'bg-zinc-900',
    secondary: 'bg-zinc-800',
    tertiary: 'bg-zinc-700',
  },
  text: {
    base: 'text-zinc-100',
    muted: 'text-zinc-300',
    accent1: 'text-sky-300',
    accent2: 'text-pink-500',
    accent3: 'text-violet-400',
  },
  button: {
    base: 'bg-sky-600 hover:bg-sky-700',
  },
  border: {
    primary: { class: 'border-zinc-800', color: '#27272a' },
    secondary: { class: 'border-zinc-700', color: '#3f3f46' },
    tertiary: { class: 'border-zinc-600', color: '#52525b' },
    accent1: { class: 'border-sky-300', color: '#7dd3fc' },
    accent2: { class: 'border-pink-500', color: '#ec4899' },
    accent3: { class: 'border-violet-400', color: '#a78bfa' },
  },
};

export { darkModeColors };
