import type { RouteObject } from 'react-router-dom';
import { organizationRoutes } from './organization.routes';
import { homeRoutes } from './home.routes';
import { sessionRoutes } from './session.routes';
import { HomeLayout } from '../layout/home/home-layout.component';
import { manageOrganizationRoutes } from './manage-organization.routes';

const routes: RouteObject[] = [
  {
    path: '/',
    Component: HomeLayout,
    children: [
      ...homeRoutes,
      ...organizationRoutes,
      ...manageOrganizationRoutes,
      ...sessionRoutes,
    ],
  },
];

export { routes };
