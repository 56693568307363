import { LoadingSpinner, Organization } from '@carousel/nexus';
import { RootState } from '../../../store/store';
import { useAppSelector } from '../../../store/hooks';
import { Error403 } from '../../session/error-403.component';

type AuthGuardProps = {
  organization: Organization;
  children: React.ReactNode;
};

const AuthGuard = ({ organization, children }: AuthGuardProps) => {
  const { profile, loading, isAuthenticated } = useAppSelector(
    (state: RootState) => state.auth,
  );

  if (loading) {
    return <LoadingSpinner />;
  }

  if (!isAuthenticated || !profile) {
    return <Error403 />;
  }

  const orgRole = profile.org_roles.find(
    (orgRole) => orgRole.name === organization.kebabCasedName,
  );

  const canManageOrg = orgRole?.roles.some(
    (role) => role.name === 'Organization Admin',
  );

  if (!canManageOrg) {
    return <Error403 />;
  }

  return <>{children}</>;
};

export { AuthGuard };
