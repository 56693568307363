import { useEffect, useCallback, useRef } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useAppDispatch } from '../store/hooks';
import { setAuth, clearAuth } from '../store/slices/auth.slice';

const useAuthState = () => {
  const {
    user,
    isAuthenticated,
    isLoading,
    getIdTokenClaims,
    getAccessTokenSilently,
  } = useAuth0();
  const dispatch = useAppDispatch();
  const initialLoadComplete = useRef(false);
  const isRefreshing = useRef(false);

  const refreshAuthState = useCallback(async () => {
    // Prevent concurrent refreshes
    if (isRefreshing.current) {
      return;
    }

    if (!isAuthenticated || !user) {
      dispatch(clearAuth());
      return;
    }

    try {
      isRefreshing.current = true;
      const accessToken = await getAccessTokenSilently({
        cacheMode: 'off',
      });
      const idTokenClaims = await getIdTokenClaims();

      if (!idTokenClaims) {
        throw new Error('No ID token claims available');
      }

      const userData = {
        sub: idTokenClaims.sub,
        email: idTokenClaims.email,
        email_verified: idTokenClaims.email_verified,
        name: idTokenClaims.name,
        nickname: idTokenClaims.nickname,
        given_name: idTokenClaims.given_name,
        family_name: idTokenClaims.family_name,
        locale: idTokenClaims.locale,
        updated_at: idTokenClaims.updated_at,
      };

      dispatch(
        setAuth({
          profile: {
            ...user,
            org_roles: idTokenClaims.org_roles ?? {},
          },
          user: userData,
          idToken: idTokenClaims.__raw,
          accessToken,
          loading: false,
        }),
      );

      return accessToken;
    } catch (error) {
      console.error('Error refreshing tokens:', error);
      dispatch(clearAuth());
      throw error;
    } finally {
      isRefreshing.current = false;
    }
  }, [
    dispatch,
    isAuthenticated,
    user,
    getIdTokenClaims,
    getAccessTokenSilently,
  ]);

  // Store refreshAuthState in a ref to avoid dependency issues
  const refreshAuthStateRef = useRef(refreshAuthState);
  refreshAuthStateRef.current = refreshAuthState;

  useEffect(() => {
    const initializeAuth = async () => {
      if (!isLoading && !initialLoadComplete.current) {
        initialLoadComplete.current = true;
        await refreshAuthStateRef.current();
      }
    };

    initializeAuth();
  }, [isLoading]);

  return { refreshAuthState };
};

export { useAuthState };
