import { NewsPost } from '@carousel/nexus';
import { CarouselError } from '../../types/errors/carousel-error.type';

type NewsState = {
  loading: boolean;
  error: CarouselError | null;
  items: NewsPost[];
};

const initialNewsState: NewsState = {
  loading: false,
  error: null,
  items: [],
};

export { initialNewsState };
export type { NewsState };
