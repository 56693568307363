import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { BannerItem } from '@carousel/nexus';

const baseUrl = import.meta.env.VITE_BFF_BASE_URL || 'https://bff.carousel.gg';

type FetchBannersProps = {
  organizationId?: string;
  divisionId?: string;
  subDivisionId?: string;
};

const fetchImages = createAsyncThunk(
  'banners/fetchImages',
  async (props: FetchBannersProps): Promise<BannerItem[]> => {
    let url = `${baseUrl}/news/banners`;
    if (props.organizationId) {
      url += `/${props.organizationId}`;
      if (props.divisionId) {
        url += `/${props.divisionId}`;
        if (props.subDivisionId) {
          url += `/${props.subDivisionId}`;
        }
      }
    }

    const response = await axios.get(url);

    if (response.status !== 200) {
      throw new Error('Error fetching images');
    }
    return response.data.banners as BannerItem[];
  },
);

export { fetchImages };
