import { Loader } from 'lucide-react';
import { ColorScheme } from '../../types/color-scheme.type';
import { darkModeColors } from '../../styles/tailwind/dark-mode.constants';

type LoadingSpinnerProps = {
  colors?: ColorScheme;
  size?: 'sm' | 'md' | 'lg';
  show?: boolean;
};

const LoadingSpinner = ({
  colors,
  size = 'md',
  show = true,
}: LoadingSpinnerProps) => {
  if (!show) return null;
  const themeColors = colors || darkModeColors;

  const sizeClasses = {
    sm: 'w-6 h-6',
    md: 'w-12 h-12',
    lg: 'w-16 h-16',
  };

  return (
    <div
      className="fixed inset-0 z-50 flex items-center justify-center bg-black/20"
      aria-live="polite"
      aria-busy="true"
    >
      <Loader
        className={`${themeColors.text.accent3} ${sizeClasses[size]} animate-spin`}
        aria-hidden="true"
      />
    </div>
  );
};

export { LoadingSpinner };
