import { useState, useEffect } from 'react';
import { Maximize2, Minimize2 } from 'lucide-react';
import { ColorScheme } from '@carousel/nexus';
import { RootState } from '../../../store/store';
import { useAppSelector } from '../../../store/hooks';

type FullScreenButtonProps = {
  colors: ColorScheme;
};

const FullScreenButton = ({ colors }: FullScreenButtonProps) => {
  const [isFullscreen, setIsFullscreen] = useState(false);
  const isMobile = useAppSelector((state: RootState) => state.layout.isMobile);

  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullscreen(document.fullscreenElement !== null);
    };

    document.addEventListener('fullscreenchange', handleFullscreenChange);
    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
    };
  }, []);

  const toggleFullscreen = async () => {
    try {
      if (!document.fullscreenElement) {
        await document.documentElement.requestFullscreen();
      } else {
        await document.exitFullscreen();
      }
    } catch (error) {
      console.error('Error toggling fullscreen:', error);
    }
  };

  return (
    <button
      onClick={toggleFullscreen}
      className={`${isMobile ? 'p-1' : 'p-2'} rounded-full ${colors.text.base} hover:${colors.text.accent1} transition-colors`}
      aria-label={isFullscreen ? 'Exit fullscreen' : 'Enter fullscreen'}
    >
      {isFullscreen ? <Minimize2 size={24} /> : <Maximize2 size={24} />}
    </button>
  );
};

export { FullScreenButton };
export type { FullScreenButtonProps };
