import {
  BarChart2,
  Building2,
  Bell,
  CircleGauge,
  Home,
  Gamepad2,
  Newspaper,
  NotepadText,
  Settings,
  HelpCircle,
} from 'lucide-react';
import { IconName } from '../types/layout/icon-item.type';

const mapNavigationItemIcon = (iconName: IconName): typeof Bell => {
  switch (iconName) {
    case IconName.BAR_CHART_2:
      return BarChart2;
    case IconName.BUILDING_2:
      return Building2;
    case IconName.CIRCLE_GUAGE:
      return CircleGauge;
    case IconName.GAMEPAD_2:
      return Gamepad2;
    case IconName.HOME:
      return Home;
    case IconName.HELP_CIRCLE:
      return HelpCircle;
    case IconName.NEWSPAPER:
      return Newspaper;
    case IconName.NOTEPAD_TEXT:
      return NotepadText;
    case IconName.SETTINGS:
      return Settings;
    default:
      return Bell;
  }
};

export { mapNavigationItemIcon };
